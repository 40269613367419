import { ADD_ITEM_TO_PRELOGIN_CART, CHECK_FIRST_VISIT, CLEAR_PRE_LOGIN_CART, FORGET_ME, GET_USER_TOKEN, HIDE_POPUP, INIT_SESSION, LOGOUT_SESSION, REMEMBER_ME, REMOVE_PRE_LOGIN_CART_ITEM, RESTAURANT_DETAILS, SHOW_POPUP, USER_CART_DETAILS, USER_CART_ITEMS, USER_PRODUCTS } from "../constants/Constants";

export const restaurantDetailsAction = (data) => ({
  type: RESTAURANT_DETAILS,
  payload: data,
});

export const getUserTokenAction = (token) => ({
  type: GET_USER_TOKEN,
  payload: { token },
});

export const logoutSession = () => ({
  type: LOGOUT_SESSION,
});

export const userCartDetails = (data) => ({
  type: USER_CART_DETAILS,
  payload: data
});

export const userCartItems = (data) => ({
  type: USER_CART_ITEMS,
  payload: data
});

export const userProducts = (data) => ({
  type: USER_PRODUCTS,
  payload: data
});

export const rememberMe = (email, password) => ({
  type: REMEMBER_ME,
  payload: { email, password },
});

export const forgetMe = () => ({
  type: FORGET_ME,
});

export const addItemToPreLoginCart = (item) => ({
  type: ADD_ITEM_TO_PRELOGIN_CART,
  payload: item,
});

export const clearPreLoginCart = () => {
  return {
    type: CLEAR_PRE_LOGIN_CART
  };
};

export const removePreLoginCartItem = (productId) => {
  return {
    type: REMOVE_PRE_LOGIN_CART_ITEM,
    payload: productId,
  };
};

export const showPopup = () => ({
  type: SHOW_POPUP,
});

export const hidePopup = () => ({
  type: HIDE_POPUP,
});

export const initSession = () => ({
  type: INIT_SESSION,
});

export const checkFirstVisit = () => ({
  type: CHECK_FIRST_VISIT,
});

