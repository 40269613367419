import React, { useEffect, useState, useRef } from "react";
import { Sheet } from "react-modal-sheet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getAllProducts,
  getProductsByColorSize,
  getAllColors,
} from "../../services/Products.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "@mui/material/Slider";
import {
  addToCart,
  cartAvailability,
  createCart,
  getCartItems,
} from "../../services/Cart.service";
import { PATH, RESTAURANT_ID } from "../../redux/constants/Constants";
import {
  userCartDetails,
  userCartItems,
  userProducts,
} from "../../redux/actions/Actions";

export default function TShirts() {
  const currencySymbol = useSelector(
    (state) => state.status.restaurant.currency_symbol
  );
  const [isOpen, setOpen] = useState(false);
  const token = useSelector((state) => state.status.restaurant.Token);
  const location = useLocation();
  const navigate = useNavigate();
  const [subCategory, setSubCategory] = useState(null);
  const [productsByColor, setProductsByColor] = useState(null);
  const [productsMainByColor, setProductsMainByColor] = useState(null);
  const [colourObjects, setColourObjects] = useState(null);
  const [firstProductData, setFirstProductData] = useState(null);
  const [range, setRange] = useState([0, 5000]);
  const [minPrice, setMinPrice] = useState("Min");
  const [minPriceSelect, setMinPriceSelect] = useState(false);
  const [maxPrice, setMaxPrice] = useState("5000+");
  const [maxPriceSelect, setMaxPriceSelect] = useState(false);
  // const [minPrices, setMinPrices] = useState([{"id" : 1, "name" : 'Min'},{"id" : 2, "name" : 250},{"id" : 3, "name" : 500},{"id" : 4, "name" : 750},{"id" : 5, "name" : 1000},{"id" : 6, "name" : 1500},{"id" : 7, "name" : 5000}]);
  // const [maxPrices, setMaxPrices] = useState([{"id" : 1, "name" : 250},{"id" : 2, "name" : 500},{"id" : 3, "name" : 750},{"id" : 4, "name" : 1000},{"id" : 5, "name" : 1500},{"id" : 6, "name" : 5000},{"id" : 7, "name" : '5000+'}]);
  // const [colors, setColors] = useState([{"id":1,"name":"Black"},{"id":2,"name":"Peach"},{"id":3,"name":"Blush Pink"},{"id":4,"name":"Sky Blue"},{"id":5,"name":"Olive Green"}])
  const [colors, setColors] = useState(null);
  const [sizes, setSizes] = useState([
    { id: 1, name: "S" },
    { id: 2, name: "M" },
    { id: 3, name: "L" },
    { id: 4, name: "XL" },
  ]);
  const [size, setSize] = useState(null);
  const [color, setColor] = useState([]);
  const [selected, setSelected] = useState(null);
  const node = useRef();

  const marks = [
    { value: 0, label: "0" },
    { value: 250, label: "250" },
    { value: 500, label: "500" },
    { value: 750, label: "750" },
    { value: 1000, label: "1000" },
    { value: 1500, label: "1500" },
    { value: 5000, label: "5000" },
    { value: 5000, label: "5000" },
  ];
  const step = roundToNearest(1000 / (marks.length - 1), 10);

  const userData = useSelector((state) => state.user.user.token);
  const cartId = useSelector(
    (state) => state.cart.cart.results && state.cart.cart.results[0]?.id
  );
  const productsData = useSelector((state) => state?.userProducts?.products);
  const dispatch = useDispatch();
  const [machColors, setMatchColors] = useState();
  // console.log(productsData);

  // Function to fetch products and set states
  const fetchProducts = async (subCategoryData) => {
    // console.log(subCategoryData);
    // Fetch products based on subCategoryData
    getAllProducts(token, "1").then((res) => {
      const activeProducts = {};
      res?.data?.results?.forEach((product) => {
        if (
          product.status === "ACTIVE" &&
          product.category === subCategoryData?.category_id
        ) {
          activeProducts[product.product_id] = product;
        }
      });
      const array = Object.values(activeProducts);
      if (array) {
        const firstProduct = array?.[0];
        setFirstProductData(firstProduct);
        if (firstProduct) {
          getProductsByColorSize(token, firstProduct.product_id).then((res) => {
            const colorObjArray = res?.data.color_obj_dict;
            setColourObjects(colorObjArray);
            // console.log(colorObjArray);
            const uniqueColors = {};
            colorObjArray?.forEach((colorObj) => {
              const { color, size, quantity } = colorObj;
              const [colorName, colorCode] = color.split(",");
              if (
                size.name === "S" ||
                size.name === "M" ||
                size.name === "L" ||
                size.name === "XL"
              ) {
                if (
                  !(colorName in uniqueColors) ||
                  (size.name === "S" && quantity > 0) ||
                  (size.name === "M" &&
                    uniqueColors[colorName].size.name !== "S" &&
                    quantity > 0) ||
                  (size.name === "L" &&
                    uniqueColors[colorName].size.name !== "S" &&
                    uniqueColors[colorName].size.name !== "M" &&
                    quantity > 0) ||
                  (size.name === "XL" &&
                    uniqueColors[colorName].size.name !== "S" &&
                    uniqueColors[colorName].size.name !== "M" &&
                    uniqueColors[colorName].size.name !== "L" &&
                    quantity > 0)
                ) {
                  uniqueColors[colorName] = colorObj;
                }
              }
            });
            const uniqueColorsArray = Object.values(uniqueColors);
            // console.log(uniqueColors);
            setProductsByColor(uniqueColorsArray);
            setProductsMainByColor(uniqueColorsArray);
            const productColors = uniqueColorsArray.map(
              (product) => product.color.split(",")[0]
            );
            getAllColors(token).then((res) => {
              setColors(res?.data?.colors);
              // console.log(res);
              const matchingColors = res?.data?.colors.filter((color) =>
                productColors.includes(color)
              );
              setMatchColors(matchingColors);
              // console.log(matchingColors);
            });
          });
        } else {
          setProductsByColor(null);
        }
      } else {
        setProductsByColor(null);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    cartId &&
      getCartItems(token, cartId).then((res) => {
        res && dispatch(userCartItems(res?.data));

        // console.log(res?.data);
      });
    // Check if subCategory data is present in location state
    const subCategoryData = location.state?.subCategory;
    // console.log(subCategoryData);

    if (subCategoryData) {
      setSubCategory(subCategoryData);
      fetchProducts(subCategoryData); // Fetch products using subCategoryData
      setColor([]);
      setSize(null);
      setRange([0, 5000]);
      setSelected(null);
    } else {
      // If subCategory data is not present, try to fetch from storage
      // const storedSubCategory = JSON.parse(localStorage.getItem('subCategory'));
      // if (storedSubCategory) {
      //   setSubCategory(storedSubCategory);
      //   fetchProducts(storedSubCategory); // Fetch products using storedSubCategory
      // }
      if (productsData) {
        setSubCategory(productsData);
        fetchProducts(productsData); // Fetch products using storedSubCategory
        setColor([]);
        setSize(null);
        setRange([0, 5000]);
        setSelected(null);
      }
    }
    getAllColorsList();
  }, [location.state]);

  const getAllColorsList = () => {
    getAllColors(token).then((res) => {
      setColors(res?.data?.colors);
      // console.log(res);
    });
  };

  // console.log(productsByColor);

  // Save subCategory to storage when changed
  // useEffect(() => {
  //   console.log(subCategory)
  //   if (subCategory) {
  //     dispatch(userProducts(subCategory));
  //     // localStorage.setItem('subCategory', JSON.stringify(subCategory));
  //   }
  // }, [subCategory]);

  const handleProductDetails = async (item) => {
    dispatch(userProducts(subCategory));

    // console.log(subCategory);

    navigate(`/product-details`, {
      state: {
        firstProductData: firstProductData,
        colorObj: colourObjects,
        productsByColor: item,
        subCategory: subCategory,
      },
    });
  };

  const handleAddToCart = (item) => {
    // console.log(item);
    if (userData) {
      cartAvailability(token, userData.id).then((res) => {
        // console.log(res?.data);
        // console.log(res?.data.results?.length < 1);
        if (res?.data.results?.length < 1) {
          const data = {
            customer_id: userData.id,
            restaurant: RESTAURANT_ID,
          };

          createCart(token, data).then((res) => {
            // console.log(res?.data);

            const data = {
              price: item.price,
              quantity: 1.0,
              cart_id: res?.data.id,
              product_id: item.product,
              color_id: item.color_id,
            };

            // console.log(data);

            addToCart(token, data).then(async (res) => {
              const resData = await res?.data;

              // console.log(resData);

              dispatch(userCartItems(resData));

              toast.success("Product added to the cart!", {
                autoClose: 1500,
                style: {
                  backgroundColor: "black",
                  color: "white",
                },
                containerId: "tShirt",
              });
            });

            cartAvailability(token, userData.id).then((res) => {
              // console.log(res?.data);
              dispatch(userCartDetails(res?.data));
            });
          });
        } else {
          const data = {
            price: item.price,
            quantity: 1.0,
            cart_id: res?.data.results[0]?.id,
            product_id: item.product,
            color_id: item.color_id,
          };

          addToCart(token, data).then(async (res) => {
            const resData = await res?.data;

            // console.log(resData);

            dispatch(userCartItems(resData));

            toast.success("Product added to the cart!", {
              autoClose: 1500,
              style: {
                backgroundColor: "black",
                color: "white",
              },
              containerId: "tShirt",
            });
          });

          // console.log(data);

          dispatch(userCartDetails(res?.data));
        }
      });
      dispatch(userProducts(subCategory));
    } else {
      toast.warning("Please log in to continue!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
        containerId: "tShirt",
      });

      setTimeout(() => {
        navigate(`/login`);
      }, 3000);
    }

    // console.log(item);
  };

  function roundToNearest(value, nearest) {
    return Math.round(value / nearest) * nearest;
  }

  function handleChanges(event, newValue) {
    // console.log(newValue);
    setRange(newValue);
    const mPrice = roundToNearest(parseInt(newValue[0]), 10);
    const lPrice = roundToNearest(parseInt(newValue[1]), 10);
    if (mPrice === 0) {
      setMinPrice("Min");
    } else {
      setMinPrice(mPrice);
    }
    setMaxPrice(lPrice);
    // console.log(mPrice);
    // console.log(lPrice);
    // console.log(color);
    // console.log(size);
    getFilterProducts(mPrice, lPrice, color, size);

    setMinPriceSelect(false);
    setMaxPriceSelect(false);
  }

  // const handleMinPriceSelect = (price) => {
  //   setMinPrice(price);
  //   if(price === 'Min')
  //     price = 0;
  //   const mPrice = parseInt(maxPrice);
  //   setRange([price, mPrice]);
  //   setMinPriceSelect(false);

  //   // Filter out objects with price less than or equal to the threshold
  //   const filteredArray = maxPrices.filter(obj => obj.name > price);
  //   console.log(filteredArray);
  //   setMaxPrices(filteredArray)
  //   getFilterProducts(price, maxPrice, color, size);
  // };

  // const handleMaxPriceSelect = (price) => {
  //   setMaxPrice(price);

  //   const mPrice = parseInt(price);
  //   setRange([minPrice, mPrice]);
  //   setMaxPriceSelect(false);
  //   getFilterProducts(minPrice, maxPrice, color, size);
  // };

  // const handleMinPriceChange = () => {
  //   setMinPriceSelect(true)
  // }

  // const handleMaxPriceChange = () => {
  //   setMaxPriceSelect(true)
  // }

  const handleColorCheckboxChange = (item) => {
    // if(item.name === 'ALL') {
    //   setColor('');
    // } else {
    let pColor = [];
    if (color.includes(item)) {
      pColor = color.filter((value) => value !== item);
      // If the item is already present, remove it
      setColor(pColor);
    } else {
      pColor = [...color, item];
      // If the item is not present, add it
      setColor([...color, item]);
    }

    getFilterProducts(minPrice, maxPrice, pColor, size);
  };

  const handleColorSizes = (item) => {
    // console.log(selected);
    selected ? setSize(item.name) : setSize(null);
    // console.log(item);
    getFilterProducts(minPrice, maxPrice, color, item.name);
  };

  const getFilterProducts = (mPrice, maxPrice, colorArray, size) => {
    // Convert price strings to numbers
    // console.log(productsByColor);
    const pMinPrice = mPrice === "Min" ? 0 : parseInt(mPrice, 10);
    const pMaxPrice = maxPrice === "5000+" ? 5000 : parseInt(maxPrice, 10);

    let filteredProducts = [];
    let productMain = productsMainByColor;
    if (pMaxPrice) {
      // console.log("iff -- 1");
      filteredProducts = productsMainByColor?.filter((item) => {
        const itemPrice = parseInt(item.price, 10);
        return itemPrice >= pMinPrice && itemPrice <= pMaxPrice;
      });
    }
    // console.log(filteredProducts);

    if (colorArray.length > 0) {
      // console.log("iff -- 2");
      // console.log("colorArray --- ", colorArray);

      filteredProducts = productsMainByColor?.filter((item) => {
        const itemPrice = parseInt(item.price, 10);
        // const itemColors = item?.color.split(",")[0];
        // const isColorMatch = colorArray.includes(itemColors);
        // const isPriceMatch = (itemPrice >= pMinPrice && itemPrice <= pMaxPrice);

        // console.log(`Checking item: ${item.id}`);
        // console.log(`Item colors: ${itemColors}`);
        // console.log(`Is color match: ${isColorMatch}`);
        // console.log(`Is price match: ${isPriceMatch}`);

        // return isColorMatch;
        return (
          item.size.name.includes(sizes.name) ||
          (colorArray.includes(item?.color.split(",")[0]) &&
            itemPrice >= pMinPrice &&
            itemPrice <= pMaxPrice)
        );
      });
    }

    if (size) {
      // console.log(size);
      // console.log("iff -- 3");
      filteredProducts = colourObjects?.filter((item) => {
        const itemPrice = parseInt(item.price, 10);
        const isPriceMatch = itemPrice >= pMinPrice && itemPrice <= pMaxPrice;

        return item.size.name === size && item.quantity > 0 && isPriceMatch;
      });
    }
    // else {
    //   filteredProducts = colourObjects;
    //   console.log("else")
    // }

    if (size && pMinPrice) {
      // console.log("iff -- 4");
      filteredProducts = colourObjects?.filter((item) => {
        const itemPrice = parseInt(item.price, 10);
        return (
          item.size.name === size &&
          itemPrice >= pMinPrice &&
          itemPrice <= pMaxPrice &&
          item.quantity > 0
        );
      });
    }

    if (size && colorArray.length > 0) {
      // console.log("iff -- 5");
      filteredProducts = colourObjects?.filter((item) => {
        const itemPrice = parseInt(item.price, 10);
        return (
          item.size.name === size &&
          colorArray.includes(item?.color.split(",")[0]) &&
          itemPrice >= pMinPrice &&
          itemPrice <= pMaxPrice &&
          item.quantity > 0
        );
      });
    }

    if (colorArray.length > 0 && pMinPrice) {
      // console.log("iff -- 6");
      filteredProducts = productsMainByColor?.filter((item) => {
        const itemPrice = parseInt(item.price, 10);
        return (
          colorArray.includes(item?.color.split(",")[0]) &&
          itemPrice >= pMinPrice &&
          itemPrice <= pMaxPrice
        );
      });
    }

    if (size && colorArray.length > 0 && pMinPrice) {
      // console.log("iff -- 7");
      filteredProducts = colourObjects?.filter((item) => {
        const itemPrice = parseInt(item.price, 10);
        return (
          item.size.name === size &&
          colorArray.includes(item?.color.split(",")[0]) &&
          itemPrice >= pMinPrice &&
          itemPrice <= pMaxPrice &&
          item.quantity > 0
        );
      });
    }
    // console.log(filteredProducts);
    setProductsByColor(filteredProducts);
    setProductsMainByColor(productMain);
  };

  // const onSizesChange = (i, item) => {
  //   setSelected((prevSelected) => (i === prevSelected ? null : i));
  //   const sizeSelected = (prevSelected) => (i === prevSelected ? null : i)
  //   console.log(i === selected ? null : i);  // To log the correct value
  //   sizeSelected ? setSize(item.name) : setSize(null);
  //   const sizeItem = sizeSelected ? item.name : null
  //   console.log(sizeItem)
  //   getFilterProducts(minPrice, maxPrice, color, sizeItem);
  // };

  const onSizesChange = (i, item) => {
    setSelected((prevSelected) => {
      const newSelected = i === prevSelected ? null : i;
      const sizeItem = newSelected === null ? null : item.name;

      // console.log(sizeItem);

      // Update the size state
      setSize(sizeItem);

      // Call the filtering function with the updated sizeItem
      getFilterProducts(minPrice, maxPrice, color, sizeItem);

      return newSelected;
    });
  };

  // useEffect(() =>rs)
  //   // console.log(matchingColors); {
  //   const productColors = productsByColor.map(product => product.color.split(',')[0]);
  //   // const matchingColors = colors.filter(color => productColors.includes(color));

  //   // setMatchColors(matchingColors)
  // },[])

  // console.log(colors);

  const clearFilters = () => {
    setRange([0, 5000]);
    setMinPrice(0);
    setMaxPrice(5000);
    setColor([]);
    setSize(null);
    setSelected(null);
    getFilterProducts(0, 5000, [], null);
    setOpen(false)
  };

  // console.log(size, minPrice > 0, maxPrice < 5000, selected, color);

  return (
    <div ref={node} style={{ minHeight: "800px" }}>
      <div className="shop-main-wrapper section-padding">
        <ToastContainer
          position="top-center"
          className="mt-4 ToastContainer"
          limit={1}
          containerId="tShirt"
        />

        <Sheet
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          className="px-2"
        // style={{ marginTop: 500 }}
        // onMouseOutCapture={() => setOpen(false)}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <div className="d-flex justify-content-end fs-4 p-3 mtn-2">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModal"
                  onClick={() => setOpen(false)}
                ></button>
              </div>

              <div className=" pt-10 mx-3">
                <div className="sidebar-wrappers">
                  <div className="sidebar-single mt-3">
                    <div
                      className="d-flex justify-content-center mb-3"
                      style={{ background: "green" }}
                    >
                      <h6
                        className="py-2"
                        style={{ color: "white", fontWeigth: 600 }}
                      >
                        {" "}
                        Filters
                      </h6>
                      {/* <hr /> */}
                    </div>
                    <div className="sidebar-body">
                      <h6>
                        <b>PRICE</b>
                      </h6>
                      <Slider
                        value={range}
                        onChange={handleChanges}
                        min={0}
                        max={5000}
                        className="mt-3 mb-0"
                        // marks={marks}
                        step={step}
                      />
                      {/* <Slider value={range} onChange={handleChanges} min={0} max={1000} className="mt-3"/> */}
                      {/* The selected range is {range[0]} - {range[1]} */}
                      <div className="text-center">
                        {currencySymbol === "₹" ? "₹" : "$"}
                        {parseInt(range[0])} -{" "}
                        {currencySymbol === "₹" ? "₹" : "$"}
                        {parseInt(range[1])}
                      </div>

                      <hr />
                      <h6 className="mb-2">
                        <b>COLOR</b>
                      </h6>
                      <ul className="price-container">
                        {machColors?.map((item, i) => (
                          <li className="" key={i}>
                            <label className="checkbox-container">
                              {item}
                              <input
                                type="checkbox"
                                name="checkbox"
                                checked={color.includes(item)}
                                onChange={() => handleColorCheckboxChange(item)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        ))}
                      </ul>
                      <hr />
                      <h6 className="mb-2">
                        <b>SIZE</b>
                      </h6>
                      {/* <label> */}
                      {/* <input type="checkbox" class="radio" value="1" name="fooby[1][]" />Kiwi</label>
                      <label>
                        <input type="checkbox" class="radio" value="1" name="fooby[1][]" />Jackfruit</label>
                      <label>
                        <input type="checkbox" class="radio" value="1" name="fooby[1][]" />Mango</label> */}
                      {/* <ul className="price-container">
                        {
                          sizes.map((item,i) => (
                        <li className="" key={i} onChange={() => handleColorSizes(item)}>
                          <label className="radio-container">
                            {item.name}
                            <input type="radio" name="radio" class="radio-sample" />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        ))
                        }
                      </ul> */}
                      <ul className="price-container">
                        {sizes.map((item, i) => (
                          <li
                            className=""
                            key={i}
                          // onChange={() => handleColorSizes(item)}
                          >
                            <label className="checkbox-container">
                              {item.name}
                              <input
                                type="checkbox"
                                name="checkbox"
                                checked={i === selected}
                                onChange={() => onSizesChange(i, item)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6">
                      {(minPrice > 0 || maxPrice < 5000 || color.length > 0 || selected || size) && <div className="d-flex justify-content-end">
                        <button
                          onClick={clearFilters}
                          className="clear-filters-button btn-success me-3"
                        >
                          Clear Filters
                        </button>
                        <button
                          className="clear-filters-button sqr-btn1"
                          onClick={() => setOpen(false)}
                        >
                          Done
                        </button>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>

        <div className="container custom-container">
          <div className="row mx-3">
            {/* <button  className="product-btn">Open sheet</button> */}

            <div className="col-md-3 order-2 order-lg-1">
              <div className="card pt-10 mx-3 web-filters h-100">
                <div className="sidebar-wrappers">
                  {/* <!-- single sidebar start --> */}
                  <div className="sidebar-single p-3">
                    <div className="sidebar-title">
                      <div className="d-flex justify-content-between">
                        <h3 style={{ padding: "5px 8px" }}>Filters</h3>
                        {(minPrice > 0 ||
                          maxPrice < 5000 ||
                          color.length > 0 ||
                          selected ||
                          size) && (
                            <button
                              onClick={clearFilters}
                              className="clear-filters-button btn-success padding-0"
                            >
                              Clear Filters
                            </button>
                          )}
                      </div>
                      <hr />
                    </div>
                    <div className="sidebar-body">
                      <h6>
                        <b>PRICE</b>
                      </h6>
                      <Slider
                        value={range}
                        onChange={handleChanges}
                        min={0}
                        max={5000}
                        className="mt-3 mb-0"
                        // marks={marks}
                        step={step}
                      />
                      {/* <Slider value={range} onChange={handleChanges} min={0} max={1000} className="mt-3"/> */}
                      {/* The selected range is {range[0]} - {range[1]} */}
                      <div className="text-center">
                        {currencySymbol === "₹" ? "₹" : "$"}
                        {parseInt(range[0])} -{" "}
                        {currencySymbol === "₹" ? "₹" : "$"}
                        {parseInt(range[1])}
                      </div>

                      {/* <div class="price-selector">
                        <div class="field">
                        <div className="dropdown">
                          <button id="dLabel" className="dropdown-select" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => handleMinPriceChange()}>
                          {currencySymbol === "₹" ? "Rs." : "$"} {minPrice}
                            <span className="caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                          </button>
                          <ul className="dropdown-menu price-list" aria-labelledby="dLabel" style={{display: minPrice && minPriceSelect != false ? 'block' : 'none'}}>
                            { minPrices?.map((item, i) => (
                              <li key={i} onClick={() => handleMinPriceSelect(item.name)} className={minPrice === item.name ? 'selected' : ''}>{currencySymbol === "₹" ? "Rs." : "$"} {item.name}</li>
                            ))}
                          </ul>
                        </div>
                        </div>
                        <div class="separator">to</div>
                        <div class="field">
                        <div className="dropdown">
                          <button id="dLabel" className="dropdown-select" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => handleMaxPriceChange()}>
                          {currencySymbol === "₹" ? "Rs." : "$"} {maxPrice}
                            <span className="caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                          </button>
                          <ul className="dropdown-menu price-list" aria-labelledby="dLabel" style={{display: maxPrice && maxPriceSelect != false ? 'block' : 'none'}}>
                            { maxPrices?.map((item,i) => (
                            <li onClick={() => handleMaxPriceSelect(item.name)} className={maxPrice === item.name ? 'selected' : ''}>{currencySymbol === "₹" ? "Rs." : "$"} {item.name}</li>
                            ))}
                          </ul>
                        </div>
                        </div>
                      </div> */}
                      <hr />
                      <h6 className="mb-2">
                        <b>COLOR</b>
                      </h6>
                      <ul className="price-container">
                        {machColors?.map((item, i) => (
                          <li className="" key={i}>
                            <label className="checkbox-container">
                              {item}
                              <input
                                type="checkbox"
                                name="checkbox"
                                checked={color.includes(item)}
                                onChange={() => handleColorCheckboxChange(item)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        ))}
                      </ul>
                      <hr />
                      <h6 className="mb-2">
                        <b>SIZE</b>
                      </h6>
                      {/* <label> */}
                      {/* <input type="checkbox" class="radio" value="1" name="fooby[1][]" />Kiwi</label>
                      <label>
                        <input type="checkbox" class="radio" value="1" name="fooby[1][]" />Jackfruit</label>
                      <label>
                        <input type="checkbox" class="radio" value="1" name="fooby[1][]" />Mango</label> */}
                      {/* <ul className="price-container">
                        {
                          sizes.map((item,i) => (
                        <li className="" key={i} onChange={() => handleColorSizes(item)}>
                          <label className="radio-container">
                            {item.name}
                            <input type="radio" name="radio" class="radio-sample" />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        ))
                        }
                      </ul> */}
                      <ul className="price-container">
                        {sizes.map((item, i) => (
                          <li
                            className=""
                            key={i}
                          // onChange={() => handleColorSizes(item)}
                          >
                            <label className="checkbox-container">
                              {item.name}
                              <input
                                type="checkbox"
                                name="checkbox"
                                checked={i === selected}
                                onChange={() => onSizesChange(i, item)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {/* <!-- single sidebar end --> */}
                </div>
              </div>
            </div>
            {productsByColor ? (
              <div className="col-md-9 order-1 order-lg-2">
                <div className="shop-product-wrapper">
                  <div className="shop-product-wrap grid row">
                    <div
                      className="product-btn1 filter"
                      style={{ display: "none" }}
                    >
                      <Link
                        to="javascript:void(0)"
                        onClick={() => setOpen(true)}
                      >
                        Filter{" "}
                        <img
                          src={`${PATH}/assets/img/bamboosa/setting.png`}
                          alt="filter"
                          className="ms-2"
                          style={{ width: "20px" }}
                        />
                      </Link>
                    </div>
                    {productsByColor &&
                      productsByColor.map((item, i) => (
                        <div
                          className="col-xl-4 col-lg-6 col-md-4 col-sm-6"
                          key={i}
                        >
                          <div className="product-item mb-30 mx-2 px-3">
                            <div
                              className="product-thumb"
                              onClick={() => handleProductDetails(item)}
                            >
                              <Link to="javascript:void(0)">
                                <img
                                  src={item.image_url?.split("@")[0]}
                                  alt="productImage"
                                  className="w-100 img-min-height zoom"
                                />
                              </Link>
                            </div>
                            <div className="product-description text-center">
                              <div className="product-name">
                                <h6
                                  className="mb-2"
                                  onClick={() => handleProductDetails(item)}
                                >
                                  <Link to="javascript:void(0)">
                                    {firstProductData?.product_name} (
                                    {item.color?.split(",")[0]})
                                  </Link>
                                </h6>
                              </div>
                              <div className="price-box">
                                <span className="regular-price">
                                  {currencySymbol === "₹" ? "₹" : "$"}
                                  {(item.price * 1).toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </span>
                                {item.price >= item.MRP ? null : (
                                  <span className="old-price ms-2">
                                    <del>
                                      {currencySymbol === "₹" ? "₹" : "$"}
                                      {(item.MRP * 1).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </del>
                                  </span>
                                )}
                                {item.price >= item.MRP ? null : (
                                  <span
                                    className="regular-price ms-2"
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {(
                                      ((item.MRP - item.price) / item.MRP) *
                                      100
                                    ).toFixed(2)}
                                    % off
                                  </span>
                                )}
                              </div>
                              {/* {item.quantity > 0 ? (
                                <div
                                  className="product-btn"
                                  onClick={() => handleAddToCart(item)}
                                >
                                  <Link to="javascript:void(0)">add to cart</Link>
                                </div>
                              ) : (
                                <div className="product-btn">
                                  <Link style={{ background: "grey" }} to="javascript:void(0)">
                                    add to cart
                                  </Link>
                                </div>
                              )} */}
                              <div
                                className="product-btn"
                                onClick={() => handleProductDetails(item)}
                              >
                                <Link to="javascript:void(0)">
                                  view details
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-12 order-1 order-lg-2">
                <div className="shop-product-wrapper">
                  <div className="shop-product-wrap grid row ms-2 text-center">
                    <h6>Products are loading.........</h6>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
