import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
// import "intl-tel-input/build/js/utils";
import {
  addUserBillingAddress,
  addUserShippingAddress,
  getProfileDetails,
  getUserBllingAddress,
  getUserShippingAddress,
  updateUserBillingAddress,
  updateUserShippingAddress,
} from "../../services/UserLogin.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createOrderId,
  createOrderDetails,
  feeCalculation,
  getPincode,
  getShippingMethod,
  createPlacedOrder,
} from "../../services/Order.service";
import { PATH, RESTAURANT_ID } from "../../redux/constants/Constants";
import { getCartItems } from "../../services/Cart.service";
import { userCartItems } from "../../redux/actions/Actions";
import useRazorpay from "react-razorpay";
import ConfettiExplosion from "react-confetti-explosion";

export default function Checkout() {
  const logo = useSelector((state) => state?.status?.restaurant?.compony_image);
  const cartItemList = useSelector(
    (state) => state?.cartItems?.cartItems?.results
  );
  const subTotal = useSelector(
    (state) => state?.cartItems?.cartItems?.total_cost
  );
  const cartId = useSelector(
    (state) => state?.cart?.cart?.results && state?.cart?.cart?.results[0]?.id
  );
  const totalValue = useSelector(
    (state) => state?.cartItems?.cartItems?.total_cost
  );
  const currencySymbol = useSelector(
    (state) => state?.status?.restaurant?.currency_symbol
  );
  const customerId = useSelector((state) => state?.user?.user?.token.id);
  const token = useSelector((state) => state?.status?.restaurant?.Token);
  const paymentID = useSelector((state) => state?.status?.restaurant?.payment_key);

  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [payment, setPayment] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);
  const [delivery, setDelivery] = useState(null);
  const [priceCalculation, setPriceCalculation] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [customerDetails, setCustomerDetails] = useState(null);
  const [Razorpay, RazorpayResponse] = useRazorpay();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const navigate = useNavigate();

  const [isExploding, setIsExploding] = useState(false);
  const scrollToPayment = useRef(null);

  // console.log(paymentID);

  const {
    register,
    reset,
    setError,
    setValue,
    clearErrors,
    getValues,
    handleSubmit: handleSubmitShipping,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmitCoupon,
    setError: setError2,
    setValue: setValue2,
    clearErrors: clearErrors2,
    formState: { errors: errors2 },
  } = useForm();

  const [pincode, setPincode] = useState(getValues("pincode"));
  pincode && pincode.length > 5 && setValue("pincode", pincode);
  // console.log(pincode);
  const [pincodeFlag, setPincodeFlag] = useState(null);

  const fullNameRef = useRef(null);
  const mobileNumberRef = useRef(null);
  const houseNumberRef = useRef(null);
  const addressLineRef = useRef(null);
  const landmarkRef = useRef(null);
  const townRef = useRef(null);
  const stateRef = useRef(null);
  const countryRef = useRef(null);
  const pincodeRef = useRef(null);
  let intlTelInputRef = useRef(null);

  useEffect(() => {
    if (errors.fullName && fullNameRef.current) {
      fullNameRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.mobileNumber && mobileNumberRef.current) {
      mobileNumberRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.houseNumber && houseNumberRef.current) {
      houseNumberRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.addressLine && addressLineRef.current) {
      addressLineRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.landmark && landmarkRef.current) {
      landmarkRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.town && townRef.current) {
      townRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.state && stateRef.current) {
      stateRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.country && countryRef.current) {
      countryRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.pincode && pincodeRef.current) {
      pincodeRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (errors.mobileNumber && intlTelInputRef.current) {
      const inputElement = intlTelInputRef.current.telInput;
      if (inputElement) {
        inputElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [errors]);

  const handleScrollToDiv = () => {
    // console.log(mobileNumber);
    if (!mobileNumber) {
      const offset = 200; // Adjust this value as needed
      const elementPosition =
        mobileNumberRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    } else {
      setError("mobileNumber", {
        type: "manual",
        message: "Enter Valid Mobile Number",
      });
    }
  };

  // useEffect(() => {
  //   if (intlTelInputRef.current) {
  //     // Accessing the input element within the IntlTelInput component
  //     intlTelInputRef.current.inputElement = intlTelInputRef.current.querySelector('input');
  //   }
  // }, []);

  const [isLoading, setIsLoading] = useState(false); // State for loading indicator

  // console.log(pincodeRef.current);

  const handleShipping = async (item) => {
    // console.log(item);
    setIsLoading(true);

    setValue2("coupon", "");
    clearErrors2("coupon");

    const pincode = getValues("pincode");
    // console.log(pincode);
    setPincode(pincode);

    try {
      const pincodeResponse = await getPincode(token, pincode);
      // console.log(pincodeResponse?.data?.delivery_codes[0]);

      // if (pincodeResponse) {
      //   console.log(pincodeResponse?.data?.delivery_codes.length < 1)

      // } else {
      //   console.log(pincodeResponse)
      // }

      if (pincodeResponse?.data?.delivery_codes.length < 1) {
        // console.log(pincodeResponse);

        setError("pincode", {
          type: "manual",
          message: "Provided pincode is not serviceable",
        });
        setPincodeFlag(false);
        // pincodeRef.current.scrollIntoView({ behavior: "smooth" });

        return; // Exit early if pincode is not serviceable
      } else {
        setError("pincode", {
          type: "manual",
          message: "Delivery is available",
        });

        // clearErrors("pincode")
        setPincodeFlag(true);
      }

      clearErrors("pincode");

      // Define a function for calculating fees
      await fee(couponCode);

      // Function to clean and format addresses
      const cleanAndFormatAddress = (addressLine, landmark) => {
        const cleanAddressLine = addressLine
          ? addressLine.trim().replace(/\s+/g, " ")
          : "";
        const cleanLandmark = landmark
          ? landmark.trim().replace(/\s+/g, " ")
          : "";

          // console.log(landmark)

        if (landmark) {
          return (
            cleanAddressLine +
            (cleanAddressLine ? ", Near - " : "") +
            cleanLandmark
          );
        } else {
          return cleanAddressLine;
        }
      };

      const address = cleanAndFormatAddress(item.addressLine, item.landmark);
      // console.log(address);

      const shippingData = {
        name: item.fullName,
        zip: item.pincode,
        address: address,
        house_number: item.houseNumber,
        city: item.town,
        priority: 1,
        state: item.state,
        customer_id: customerId,
        country: item.country,
        phone_number: mobileNumber || shippingAddress?.phone_number,
      };

      setPincode(item.pincode);

      // console.log(shippingData);

      const shippingResponse = customerId
        ? await getUserShippingAddress(token, customerId)
        : { data: { results: [] } }; // If no customerId, return empty results

      // console.log(shippingResponse?.data);

      if (shippingResponse?.data.results.length > 0) {
        const shippingID = shippingResponse.data.results[0]?.id;
        const updatedShippingAddress = await updateUserShippingAddress(
          token,
          shippingID,
          shippingData
        );
        // console.log(updatedShippingAddress?.data);
        setShippingAddress(updatedShippingAddress?.data);
      } else {
        const newShippingAddress = await addUserShippingAddress(
          token,
          shippingData
        );
        // console.log(newShippingAddress?.data);
        setShippingAddress(newShippingAddress?.data);
      }

      // Function to clean and format addresses (another function for billing)
      const cleanAndFormatAddress2 = (address) => {
        const cleanAddress = address ? address.trim().replace(/\s+/g, " ") : "";
        return cleanAddress;
      };

      const address2 = cleanAndFormatAddress2(item.address);
      // console.log(address2);

      const billingData = {
        name: item.fullName2,
        zip: item.pincode2,
        address: address2,
        house_number: item.houseNumber2,
        city: item.town2,
        priority: 1,
        state: item.state2,
        customer_id: customerId,
        country: item.country2,
      };

      // console.log(billingData);

      const billingResponse = customerId
        ? await getUserBllingAddress(token, customerId)
        : { data: { results: [] } }; // If no customerId, return empty results

      // console.log(billingResponse?.data);

      if (billingResponse?.data.results.length > 0) {
        const billingID = billingResponse.data.results[0]?.id;
        const updatedBillingAddress = await updateUserBillingAddress(
          token,
          billingID,
          billingData
        );
        // console.log(updatedBillingAddress?.data);
        setBillingAddress(updatedBillingAddress?.data);
      } else {
        const newBillingAddress = await addUserBillingAddress(
          token,
          billingData
        );
        // console.log(newBillingAddress?.data);
        setBillingAddress(newBillingAddress?.data);
      }

      // Set payment status after both addresses are processed
      setPayment(true);
    } catch (error) {
      console.error("Error handling shipping:", error);
      // Handle any errors that occur
    } finally {
      setIsLoading(false); // Ensure loading state is turned off regardless of success or error
    }
  };

  // Usage example

  // const handleShipping = (item) => {
  //   console.log(item);
  //   setIsLoading(true);

  //   setValue2("coupon", "");
  //   clearErrors2("coupon");

  //   const pincode = getValues("pincode");
  //   console.log(pincode);
  //   setPincode(pincode);

  //   getPincode(token, pincode).then((res) => {
  //     console.log(res?.data?.delivery_codes[0]);
  //     if (!res?.data?.delivery_codes[0]) {
  //       setError("pincode", {
  //         type: "manual",
  //         message: "Provided pincode is not serviceable",
  //       });
  //     } else {
  //       clearErrors("pincode");

  //       fee(couponCode);

  //       function cleanAndFormatAddress(addressLine, landmark) {
  //         const cleanAddressLine = addressLine
  //           ? addressLine.trim().replace(/\s+/g, " ")
  //           : "";
  //         const cleanLandmark = landmark
  //           ? landmark.trim().replace(/\s+/g, " ")
  //           : "";
  //         return (
  //           cleanAddressLine +
  //           (cleanAddressLine ? ", Near - " : "") +
  //           cleanLandmark
  //         );
  //       }

  //       const address = cleanAndFormatAddress(item.addressLine, item.landmark);
  //       console.log(address);

  //       const shippingData = {
  //         name: item.fullName,
  //         zip: item.pincode,
  //         address: address,
  //         house_number: item.houseNumber,
  //         city: item.town,
  //         priority: 1,
  //         state: item.state,
  //         customer_id: customerId,
  //         country: item.country,
  //         phone_number: mobileNumber || shippingAddress?.phone_number,
  //       };

  //       setPincode(item.pincode);

  //       console.log(shippingData);

  //       customerId &&
  //         getUserShippingAddress(token, customerId).then((res) => {
  //           console.log(res?.data);
  //           setShippingAddress(res?.data.results[0]);
  //           if (res?.data.results.length > 0) {
  //             const shippingID = res?.data.results[0]?.id;

  //             updateUserShippingAddress(token, shippingID, shippingData).then(
  //               (res) => {
  //                 console.log(res?.data);
  //                 setShippingAddress(res?.data);
  //               }
  //             );
  //           } else {
  //             addUserShippingAddress(token, shippingData).then((res) => {
  //               console.log(res?.data);
  //               setShippingAddress(res?.data);
  //             });
  //           }
  //         });

  //       function cleanAndFormatAddress2(address) {
  //         const cleanAddress = address
  //           ? address.trim().replace(/\s+/g, " ")
  //           : "";
  //         return cleanAddress;
  //       }

  //       const address2 = cleanAndFormatAddress2(item.address);
  //       console.log(address2);

  //       const billingData = {
  //         name: item.fullName2,
  //         zip: item.pincode2,
  //         address: address2,
  //         house_number: item.houseNumber2,
  //         city: item.town2,
  //         priority: 1,
  //         state: item.state2,
  //         customer_id: customerId,
  //         country: item.country2,
  //       };

  //       console.log(billingData);

  //       customerId &&
  //         getUserBllingAddress(token, customerId).then((res) => {
  //           console.log(res?.data);
  //           setBillingAddress(res?.data.results[0]);
  //           if (res?.data.results.length > 0) {
  //             const billingID = res?.data.results[0]?.id;

  //             updateUserBillingAddress(token, billingID, billingData).then(
  //               (res) => {
  //                 console.log(res?.data);
  //                 setBillingAddress(res?.data);
  //                 setPayment(true);
  //               }
  //             );
  //           } else {
  //             addUserBillingAddress(token, billingData).then((res) => {
  //               console.log(res?.data);
  //               setBillingAddress(res?.data);
  //               setPayment(true);
  //             });
  //           }
  //         });
  //     }
  //   });
  // };

  // console.log(shippingAddress);

  const fee = (couponCode) => {
    const pincode = getValues("pincode");

    const data = {
      no_tax_total: 0,
      shipping_id: delivery?.id,
      restaurant_id: RESTAURANT_ID,
      customer_id: customerId,
      sub_total: subTotal,
      tip: 0,
      custom_tip: 0,
      coupon: couponCode,
      cart_id: cartId,
      type: "ecommerce",
      destination_pincode: pincode,
    };

    // console.log(data);

    // shippingAddress &&
    pincode &&
      feeCalculation(token, data).then((res) => {
        // console.log(token, data);
        // console.log(res);
        res ? setPriceCalculation(res?.data) : setPriceCalculation(null);
        res && setIsLoading(false);

        if (res) {
          const offset = 100; // Adjust this value as needed
          const elementPosition =
            scrollToPayment.current.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    cartId &&
      getCartItems(token, cartId).then((res) => {
        res && dispatch(userCartItems(res?.data));

        // console.log(res?.data);
      });

    fee(couponCode);

    fetchAPIs();
  }, []);

  // console.log(customerDetails);

  const fetchAPIs = () => {
    customerId &&
      getProfileDetails(token, customerId).then((res) => {
        // console.log(res);
        setCustomerDetails(res?.data.results[0]);
      });

    cartId &&
      getCartItems(token, cartId).then((res) => {
        dispatch(userCartItems(res?.data));

        // console.log(res?.data);
      });

    customerId &&
      getUserShippingAddress(token, customerId).then((res) => {
        // console.log(res?.data);
        setShippingAddress(res?.data.results[0]);
      });

    customerId &&
      getUserBllingAddress(token, customerId).then((res) => {
        // console.log(res?.data);
        setBillingAddress(res?.data.results[0]);
      });

    getShippingMethod(token).then((res) => {
      // console.log(res?.data);
      const filteredResults = res?.data?.results?.filter(
        (entry) => (entry.name === "Delivery") | "delivery"
      );
      // console.log(filteredResults);
      setDelivery(filteredResults[0]);
    });
  };

  // console.log(delivery);

  const [useSameBillingAddress, setUseSameBillingAddress] = useState(false);
  const [usePreviousShippinggAddress, setUsePreviousShippinggAddress] =
    useState(false);

  const handleSameBillingAddress = () => {
    // getValues("fullName") && clearErrors("fullName")
    // getValues("houseNumber") && clearErrors("houseNumber")
    // getValues("addressLine") && clearErrors("addressLine")
    // getValues("landmark") && clearErrors("landmark")
    // getValues("town") && clearErrors("town")
    // getValues("state") && clearErrors("state")
    // getValues("country") && clearErrors("country")
    // getValues("pincode") && clearErrors("pincode")

    const requiredFields = [
      "fullName",
      "houseNumber",
      "addressLine",
      // "landmark",
      "town",
      "state",
      "country",
      "pincode",
      "mobileNumber",
    ];

    const fieldDisplayNames = {
      fullName: "Full Name",
      houseNumber: "House Number",
      addressLine: "Address Line",
      // landmark: "Landmark",
      town: "Town",
      state: "State",
      country: "Country",
      pincode: "Pincode",
      mobileNumber: "Mobile Number",
    };

    const missingFields = requiredFields.filter((field) => !getValues(field));

    if (missingFields.length === 0) {
      clearErrors("sameBilling");
      setUseSameBillingAddress(!useSameBillingAddress);
      if (!useSameBillingAddress) {
        // If checkbox is checked, copy shipping address to billing address
        const addressValue = `${getValues("addressLine")}${
          getValues("landmark") ? "," : ""
        } ${getValues("landmark") ? getValues("landmark") : ""}`;

        // console.log(addressValue)

        setValue("fullName2", getValues("fullName"));
        addressValue !== " " && setValue("address", addressValue);
        setValue("houseNumber2", getValues("houseNumber"));
        setValue("town2", getValues("town"));
        setValue("state2", getValues("state"));
        setValue("country2", getValues("country"));
        setValue("pincode2", getValues("pincode"));
        // setValue("mobileNumber2", getValues("mobileNumber"));

        clearErrors();
      } else {
        // If checkbox is unchecked, clear billing address fields
        setValue("fullName2", "");
        setValue("houseNumber2", "");
        setValue("address", "");
        setValue("town2", "");
        setValue("state2", "");
        setValue("country2", "");
        setValue("pincode2", "");
        // setValue("mobileNumber2", "");
      }
    } else {
      setUseSameBillingAddress(false);
      // setError("sameBilling", {
      //   type: "manual",
      //   message: "Please fill all the required fields",
      // });
      missingFields.forEach((field) => {
        setError(field, {
          type: "manual",
          message: `${fieldDisplayNames[field]} is required`,
        });
      });
    }
  };

  const handlePreviousShippingAddress = () => {
    // console.log(1);
    setUsePreviousShippinggAddress(!usePreviousShippinggAddress);
    setUseSameBillingAddress(false);
    if (!usePreviousShippinggAddress) {
      // If checkbox is checked, copy shipping address to billing address

      if (shippingAddress) {
        setValue("fullName", shippingAddress?.name);
        setValue("houseNumber", shippingAddress?.house_number);
        setPincode(shippingAddress?.zip);
        const address = shippingAddress?.address.split("Near - ")[0];
        let cleanedAddress;
        if (address) {
          const lastCommaIndex = address.lastIndexOf(",");
          let tempAddress =
            lastCommaIndex !== -1
              ? address.substring(0, lastCommaIndex) +
                address.substring(lastCommaIndex + 1)
              : address;

          // Remove blank spaces excluding those between words
          cleanedAddress = tempAddress.replace(/\s+/g, " ").trim();
          // console.log(cleanedAddress);
        }
        setValue("addressLine", cleanedAddress);
        setValue("landmark", shippingAddress?.address?.split("Near - ")[1]);
        setValue("town", shippingAddress?.city);
        setValue("state", shippingAddress?.state);
        setValue("country", shippingAddress?.country);
        setValue("pincode", shippingAddress?.zip);
        setMobileNumber(shippingAddress?.phone_number);

        currencySymbol === "₹"
          ? setPhoneNumber(shippingAddress?.phone_number?.split("+91")[1])
          : setPhoneNumber(shippingAddress?.phone_number?.split("+1")[1]);

        const phone_number =
          currencySymbol === "₹"
            ? shippingAddress?.phone_number?.split("+91")[1]
            : shippingAddress?.phone_number?.split("+1")[1];

        // console.log(shippingAddress);

        phone_number && intlTelInputRef.current.setNumber(phone_number);
        phone_number && setValue("mobileNumber", phone_number);

        if (intlTelInputRef.current.inputElement) {
          intlTelInputRef.current.inputElement.value = phone_number;
        }

        clearErrors();
      }

      if (billingAddress) {
        // console.log(0);
        setValue("fullName2", billingAddress?.name);
        setValue("houseNumber2", billingAddress?.house_number);
        setValue("address", billingAddress?.address);
        setValue("town2", billingAddress?.city);
        setValue("state2", billingAddress?.state);
        setValue("country2", billingAddress?.country);
        setValue("pincode2", billingAddress?.zip);
        clearErrors();
      }
    } else {
      // If checkbox is unchecked, clear billing address fields
      setValue("fullName", "");
      setValue("houseNumber", "");
      setValue("addressLine", "");
      setValue("landmark", "");
      setValue("town", "");
      setValue("state", "");
      setValue("country", "");
      setValue("pincode", "");

      setValue("fullName2", "");
      setValue("houseNumber2", "");
      setValue("address", "");
      setValue("town2", "");
      setValue("state2", "");
      setValue("country2", "");
      setValue("pincode2", "");

      setMobileNumber(null);
      setPhoneNumber(null);

      intlTelInputRef.current.setNumber("");
    }
  };

  const inputMobileNumber = () => {
    // console.log(intlTelInputRef);

    let mobileNumber = intlTelInputRef.current.state.value;
    // mobileNumber = mobileNumber.replace(/^\s+|\s+$/g, ""); // Remove leading and trailing spaces
    mobileNumber = mobileNumber.replace(/\s+/g, ""); // Remove all spaces
    mobileNumber = mobileNumber.replace(/^0+/, ""); // Remove leading zeros

    setMobileNumber(mobileNumber);
    setPhoneNumber(mobileNumber);

    const mobileNumberPattern = /^[0-9]{10}$/;
    // console.log(mobileNumber);
    const code = intlTelInputRef.current?.state.title.split(" ")[2];

    if (mobileNumberPattern.test(mobileNumber)) {
      clearErrors("mobileNumber");
      setValue("mobileNumber", code + mobileNumber);
      setMobileNumber(code + mobileNumber);
    } else {
      setError("mobileNumber", {
        type: "manual",
        message: "Enter Valid Mobile Number",
      });
      setMobileNumber(null);
    }

    if (!mobileNumber) {
      setError("mobileNumber", {
        type: "manual",
        message: "Enter Mobile Number", // "Enter Mobile Number"
      });
      setValue("mobileNumber", null);
    }

    // Update the input value directly
    if (intlTelInputRef.current.inputElement) {
      intlTelInputRef.current.inputElement.value = mobileNumber;
    }
    // console.log("Mobile Number", code + mobileNumber);
  };

  const handleInputChange = (e) => {
    // console.log(e.target.value);
    const value = e.target.value;
    setPhoneNumber(value);
  };

  const handleCoupon = (item) => {
    setIsExploding(false);
    // console.log(item);
    const pincode = getValues("pincode");

    setCouponCode(item.coupon);

    const data = {
      no_tax_total: 0,
      shipping_id: delivery?.id,
      restaurant_id: RESTAURANT_ID,
      customer_id: customerId,
      sub_total: subTotal,
      tip: 0,
      custom_tip: 0,
      coupon: item.coupon,
      cart_id: cartId,
      type: "ecommerce",
      destination_pincode: pincode,
    };

    // shippingAddress &&
    pincode &&
      feeCalculation(token, data).then((res) => {
        // console.log(res);
        if (!res.error) {
          // console.log(res);
          setPriceCalculation(res?.data);
          setIsExploding(true);
        } else {
          setCouponCode("");
          // setPriceCalculation(null);
          // console.log(res);
          const couponError = res?.error;

          const data = {
            no_tax_total: 0,
            shipping_id: delivery?.id,
            restaurant_id: RESTAURANT_ID,
            customer_id: customerId,
            sub_total: subTotal,
            tip: 0,
            custom_tip: 0,
            coupon: "",
            cart_id: cartId,
            type: "ecommerce",
            destination_pincode: pincode,
          };

          feeCalculation(token, data).then((res) => {
            // console.log(res);
            if (res) {
              setPriceCalculation(res?.data);
            }
            setError2("coupon", {
              type: "manual",
              // message: "Invalid Coupon",
              message: couponError,
            });
            setIsExploding(false);
          });
        }
      });
  };

  const handlePincode = (e) => {
    clearErrors("pincode");

    setPincodeFlag(null);
    setValue2("coupon", "");
    clearErrors2("coupon");
    setCouponCode("");
    setIsExploding(false);
    // console.log(e.target.value);
    const pincode = e.target.value;
    setPincode(e.target.value);
    setPriceCalculation(null);
    getPincode(token, pincode).then((res) => {
      // console.log(res?.data);
      setPincodeFlag(null);
      // if (!res?.data?.delivery_codes[0]) {
      //   setError("pincode", {
      //     type: "manual",
      //     message: "Provided pincode is not serviceable",
      //   });
      // } else {
      // }

    if (pincode.length > 5) {
      if (!res?.data?.delivery_codes[0]) {
        setError("pincode", {
          type: "manual",
          message: "Provided pincode is not serviceable",
        });
        setPincodeFlag(false);
      } else {
        setError("pincode", {
          type: "manual",
          message: "Delivery is available",
        });
        // clearErrors("pincode")
        setPincodeFlag(true);
      }
    } else {
      clearErrors("pincode");
      // setPincode(null);
    }
    });
  };

  const handlePayment = async () => {
    // Fetch the order from the server

    const amount = priceCalculation?.sub_total - priceCalculation?.discount;

    const data = {
      amount: amount,
      currency: "INR",
    };
    // console.log(data);

    JSON.stringify(data);

    createOrderId(token, data).then((res) => {
      // console.log(res?.data);
      if (res?.data?.amount && customerDetails) {
        const order = res?.data;

        const options = {
          key: paymentID, // Replace with your Razorpay test key ID
          amount: order.amount, // Amount in paisa
          currency: order.currency,
          name: "BambooSA",
          description: "Payment",
          image: logo, // /assets/img/bamboosa/logo.png", // Logo URL
          order_id: order.id, // Order ID from server
          prefill: {
            name:
              customerDetails.customer.first_name +
              " " +
              customerDetails.customer.last_name,
            email: customerDetails.customer.email,
            contact: customerDetails.customer.phone_number,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#008000", // Theme color
          },
          handler: function (response) {
            // console.log(response);
            // Handle payment success
            const paymentId = response.razorpay_payment_id;
            // alert(`Payment Successful: ${response.razorpay_payment_id}`);
            if (
              response &&
              shippingAddress &&
              billingAddress &&
              priceCalculation
            ) {
              const shipping_address =
                shippingAddress?.name +
                ", " +
                shippingAddress?.house_number +
                ", " +
                shippingAddress?.address +
                ", " +
                shippingAddress?.city +
                " - " +
                shippingAddress?.zip +
                ", " +
                shippingAddress?.state +
                ", " +
                shippingAddress?.country;

              const billing_address =
                billingAddress?.name +
                ", " +
                billingAddress?.house_number +
                ", " +
                billingAddress?.address +
                ", " +
                billingAddress?.city +
                " - " +
                billingAddress?.zip +
                ", " +
                billingAddress?.state +
                ", " +
                billingAddress?.country;

              const completeShippingAddress = {
                name: shippingAddress?.name,
                add: shipping_address,
                pin: shippingAddress?.zip,
                city: shippingAddress?.city,
                state: shippingAddress?.state,
                country: shippingAddress?.country,
                phone: mobileNumber,
              };

              // console.log(completeShippingAddress);
              const data = {
                subtotal: priceCalculation.sub_total,
                total: priceCalculation.sub_total - priceCalculation.discount,
                // shipping_fee: priceCalculation.shipping_fee,
                shipping_fee: 0,
                currency: "INR",
                tip: priceCalculation.tip,
                discount: priceCalculation.discount,
                extra: "",
                tax: priceCalculation.tax,
                cart_id: cartId,
                service_fee: priceCalculation.service_fee,
                customer: customerId,
                status: "active",
                shipping_address_text: shipping_address,
                billing_address_text: billing_address,
                complete_shipping_address: JSON.stringify(
                  completeShippingAddress
                ),
                coupon_code: couponCode,
              };
              // console.log(data);
              createOrderDetails(token, data).then((res) => {
                // console.log(res?.data);

                getCartItems(token, cartId).then((res) => {
                  dispatch(userCartItems(res?.data));
                  // console.log(res?.data);
                });
                if (res?.data) {
                  // console.log(res?.data.order_id);

                  const billingData = res?.data;

                  const data = {
                    type: "Online",
                    amount: billingData.total,
                    currency: billingData.currency,
                    receipt_email: customerDetails.customer.email,
                    source: "web",
                    card: {
                      number: "4111111111111111",
                      exp_month: "12",
                      exp_year: "9999",
                      cvc: "555",
                      name:
                        customerDetails.customer.first_name +
                        " " +
                        customerDetails.customer.last_name,
                    },
                    metadata: {
                      order_id: billingData.order_id,
                      shippingmethod_id: delivery.id,
                      restaurant_id: RESTAURANT_ID,
                      phone: mobileNumber,
                      customer_id: customerId,
                      special_instruction: "",
                      name: billingAddress?.name,
                    },
                    billing_details: {
                      address: {
                        city: billingAddress?.city,
                        state: billingAddress?.state,
                        line1: billingAddress?.address,
                        line2: "",
                        house_no: billingAddress.house_number,
                        postal_code: billingAddress?.zip,
                      },
                    },

                    o_type: "ecommerce",
                    transaction_id: paymentId,
                    payment_method: "Prepaid", // Card / UPI
                    cart_id: cartId,
                  };

                  createPlacedOrder(token, data).then(async (res) => {
                    const resData = await res?.data;
                    // console.log(res);
                    if (resData) {
                      // toast.success("Order Placed...!", {
                      //   autoClose: 1500,
                      //   style: {
                      //     backgroundColor: "black",
                      //     color: "white",
                      //   },
                      // });
                      setPriceCalculation(null);
                      // setTimeout(() => {
                      navigate(`/my-account/orders`, {
                        state: { paymentSuccessful: true },
                      });
                      // }, 100);
                    }
                  });
                  // console.log(data);
                }
              });
            }
          },
          modal: {
            ondismiss: function () {
              // console.log("Checkout form closed");
            },
          },
        };

        const rzp1 = new Razorpay(options);
        rzp1.open();
      }
    });
  };

  return (
    <div>
      {/* <!-- checkout main wrapper start --> */}
      <div className="checkout-page-wrapper section-padding">
        <ToastContainer
          position="top-center"
          className="mt-4 ToastContainer"
          limit={1}
          containerId="checkout"
        />

        <div className="container custom-container">
          {shippingAddress && (
            <div className="row">
              <div className="col-12">
                {/* <!-- Checkout Login Coupon Accordion Start --> */}
                <div className="checkoutaccordion" id="checkOutAccordion">
                  <div className="card">
                    <h3>
                      Note: If using a new address, uncheck the current one and
                      fill in the fields below
                    </h3>
                    <div className="row px-4">
                      <div className="col-lg-2">
                        <div className="form-check mt-3">
                          <input
                            className="form-check-input p-2"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            onChange={handlePreviousShippingAddress}
                          />
                          <label
                            className="form-check-label fs-6 fw-bold"
                            for="flexCheckDefault"
                          >
                            Use Existing Address
                          </label>
                        </div>
                      </div>
                      <div className="card-body col-lg-10">
                        {/* <div className="cart-update-option"> */}
                        <div className="apply-coupon-wrapper">
                          <h6>
                            {shippingAddress?.name +
                              ", " +
                              shippingAddress?.house_number +
                              ", " +
                              shippingAddress?.address +
                              ", " +
                              shippingAddress?.city +
                              " - " +
                              shippingAddress?.zip +
                              ", " +
                              shippingAddress?.state +
                              ", " +
                              shippingAddress?.country}
                          </h6>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Checkout Login Coupon Accordion End --> */}
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-lg-8">
              <form onSubmit={handleSubmitShipping(handleShipping)}>
                {/* <!-- Checkout Shipping & Billing Details --> */}
                {/* Shipping Details */}
                <div className="checkout-billing-details-wrap card p-4 m-3">
                  <h2>Shipping Details</h2>
                  <div className="billing-form-wrap">
                    <div
                      className="checkoutaccordion mt-4"
                      id="checkOutAccordion"
                    >
                      <h4>Personal Information</h4>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="single-input-item mx-2">
                          <input
                            ref={fullNameRef}
                            type="text"
                            id="f_name"
                            // disabled={useSameBillingAddress}
                            placeholder="Full Name"
                            {...register("fullName", {
                              required: "Enter Full Name",
                            })}
                            onChange={(e) => {
                              clearErrors("fullName");
                              setValue("fullName", e.target.value);
                            }}
                            maxLength={50}
                          />
                          {errors.fullName && (
                            <p className="error">{errors.fullName.message}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="single-input-item mx-2"
                          ref={mobileNumberRef}
                        >
                          {/* <IntlTelInput
                            {...register("mobileNumber", {
                              required: "Enter Mobile Number",
                            })}
                            ref={intlTelInputRef}
                            containerClassName="intl-tel-input"
                            inputClassName="form-control"
                            defaultCountry={
                              currencySymbol === "₹" ? "in" : "us"
                            }
                            autoPlaceholder=""
                            placeholder="Mobile Number"
                            onPhoneNumberChange={() => inputMobileNumber()}
                            defaultValue={phoneNumber || mobileNumber || ""}
                            // value={phoneNumber || mobileNumber || ""}
                            onlyCountries={["in", "us"]}
                            // onChange={handleInputChange}
                          /> */}
                          <IntlTelInput
                            {...register("mobileNumber", {
                              required: "Enter Mobile Number",
                            })}
                            ref={intlTelInputRef}
                            containerClassName="intl-tel-input"
                            inputClassName="form-control"
                            defaultCountry={
                              currencySymbol === "₹" ? "in" : "us"
                            }
                            autoPlaceholder=""
                            placeholder="Mobile Number"
                            onPhoneNumberChange={() => inputMobileNumber()}
                            defaultValue={phoneNumber || mobileNumber || ""}
                            onlyCountries={["in", "us"]}
                            separateDialCode={true}
                          />
                          {errors.mobileNumber && (
                            <p className="error">
                              {errors.mobileNumber.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="checkoutaccordion mt-4"
                      id="checkOutAccordion"
                    >
                      <h4>Address</h4>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="single-input-item mb-2 mx-2">
                          <input
                            ref={houseNumberRef}
                            type="text"
                            id="street-address"
                            placeholder="House No. / Building / Society"
                            {...register("houseNumber", {
                              required: "Enter House No. / Building / Society",
                            })}
                            onChange={(e) => {
                              clearErrors("houseNumber");
                              setValue("houseNumber", e.target.value);
                            }}
                            maxLength={50}
                          />
                          {errors.houseNumber && (
                            <p className="error">
                              {errors.houseNumber.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="single-input-item mb-2 mx-2">
                          <input
                            ref={addressLineRef}
                            type="text"
                            id="street-address"
                            placeholder="Address Line"
                            {...register("addressLine", {
                              required: "Enter Address Line",
                            })}
                            onChange={(e) => {
                              clearErrors("addressLine");
                              setValue("addressLine", e.target.value);
                            }}
                            maxLength={200}
                          />
                          {errors.addressLine && (
                            <p className="error">
                              {errors.addressLine.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="single-input-item mb-2 mx-2">
                          <input
                            ref={landmarkRef}
                            type="text"
                            id="street-address"
                            placeholder="Landmark"
                            {...register(
                              "landmark"
                              //   , {
                              //   required: "Enter Landmark",
                              // }
                            )}
                            onChange={(e) => {
                              clearErrors("landmark");
                              setValue("landmark", e.target.value);
                            }}
                            maxLength={200}
                          />
                          {/* {errors.landmark && (
                            <p className="error">{errors.landmark.message}</p>
                          )} */}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="single-input-item mx-2">
                          <input
                            ref={townRef}
                            type="text"
                            id="town"
                            placeholder="Town / City"
                            {...register("town", {
                              required: "Enter Town / City",
                            })}
                            onChange={(e) => {
                              clearErrors("town");
                              setValue("town", e.target.value);
                            }}
                            maxLength={20}
                          />
                          {errors.town && (
                            <p className="error">{errors.town.message}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="single-input-item mx-2">
                          <input
                            ref={stateRef}
                            type="text"
                            id="state"
                            placeholder="State / Division"
                            {...register("state", {
                              required: "Enter State / Division",
                            })}
                            onChange={(e) => {
                              clearErrors("state");
                              setValue("state", e.target.value);
                            }}
                            maxLength={20}
                          />
                          {errors.state && (
                            <p className="error">{errors.state.message}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="single-input-item mx-2">
                          <input
                            ref={countryRef}
                            type="text"
                            id="state"
                            placeholder="Country"
                            {...register("country", {
                              required: "Enter Country",
                            })}
                            onChange={(e) => {
                              clearErrors("country");
                              setValue("country", e.target.value);
                            }}
                            maxLength={20}
                          />
                          {errors.country && (
                            <p className="error">{errors.country.message}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="single-input-item mx-2">
                          <input
                            ref={pincodeRef}
                            type="text"
                            id="postcode"
                            placeholder="Postcode / ZIP"
                            {...register("pincode", {
                              required: "Enter Postcode / ZIP",
                              pattern: {
                                value: /^\d{6}$/,
                                message: "Invalid Postcode / ZIP",
                              },
                            })}
                            maxLength={6}
                            onChange={(e) => handlePincode(e)}
                          />
                          {/* {errors.pincode && (
                            <p className="error">{errors.pincode.message}</p>
                          )} */}
                          {pincodeFlag
                            ? errors.pincode && (
                                <p
                                  className="error fw-bold"
                                  style={{ color: "green" }}
                                >
                                  {errors.pincode.message}
                                </p>
                              )
                            : errors.pincode && (
                                <p className="error fw-bold">
                                  {errors.pincode.message}
                                </p>
                              )}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mt-2">
                      <div className="checkout-box-wrap">
                        <div className="single-input-item mt-4 mx-2">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input p-2"
                              id="create_pwd"
                              checked={useSameBillingAddress}
                              {...register("sameBilling")}
                              onChange={handleSameBillingAddress}
                            />
                            <label
                              className="form-check-label fw-bold"
                              for="create_pwd"
                            >
                              Use Shipping Address for Billing
                            </label>
                          </div>
                        </div>
                        {errors.sameBilling && (
                          <p className="error">{errors.sameBilling.message}</p>
                        )}
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                </div>

                {/* Billing Details */}
                <div className="checkout-billing-details-wrap card p-4 m-3">
                  <h2>Billing Details</h2>
                  <div className="billing-form-wrap">
                    {/* <form action="#"> */}
                    <div
                      className="checkoutaccordion mt-4"
                      id="checkOutAccordion"
                    >
                      <h4>Personal Information</h4>
                    </div>
                    <div className="col-md-12">
                      <div className="single-input-item mx-2">
                        <input
                          type="text"
                          id="f_name"
                          placeholder="Full Name"
                          disabled={useSameBillingAddress}
                          {...register("fullName2", {
                            required: "Enter Full Name",
                          })}
                          maxLength={50}
                          // ref={fu}
                        />
                        {errors.fullName2 && (
                          <p className="error">{errors.fullName2.message}</p>
                        )}
                      </div>
                    </div>

                    <div
                      className="checkoutaccordion mt-4"
                      id="checkOutAccordion"
                    >
                      <h4>Address</h4>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="single-input-item mb-2 mx-2">
                          <input
                            type="text"
                            id="street-address"
                            placeholder="House No. / Building / Society"
                            disabled={useSameBillingAddress}
                            {...register("houseNumber2", {
                              required: "Enter House No. / Building / Society",
                            })}
                            maxLength={50}
                          />
                          {errors.houseNumber2 && (
                            <p className="error">
                              {errors.houseNumber2.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="single-input-item mb-2 mx-2">
                          <input
                            type="text"
                            id="street-address"
                            placeholder="Address"
                            disabled={useSameBillingAddress}
                            {...register("address", {
                              required: "Enter Address",
                            })}
                            maxLength={200}
                          />
                          {errors.address && (
                            <p className="error">{errors.address.message}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="single-input-item mx-2">
                          <input
                            type="text"
                            id="town"
                            placeholder="Town / City"
                            disabled={useSameBillingAddress}
                            {...register("town2", {
                              required: "Enter Town / City",
                            })}
                            maxLength={20}
                          />
                          {errors.town2 && (
                            <p className="error">{errors.town2.message}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="single-input-item mx-2">
                          <input
                            type="text"
                            id="state"
                            placeholder="State / Division"
                            disabled={useSameBillingAddress}
                            {...register("state2", {
                              required: "Enter State / Division",
                            })}
                            maxLength={20}
                          />
                          {errors.state2 && (
                            <p className="error">{errors.state2.message}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="single-input-item mx-2">
                          <input
                            type="text"
                            id="state"
                            placeholder="Country"
                            disabled={useSameBillingAddress}
                            {...register("country2", {
                              required: "Enter Country",
                            })}
                            maxLength={20}
                          />
                          {errors.country2 && (
                            <p className="error">{errors.country2.message}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="single-input-item mb-3 mx-2">
                          <input
                            type="text"
                            id="postcode"
                            placeholder="Postcode / ZIP"
                            disabled={useSameBillingAddress}
                            {...register("pincode2", {
                              required: "Enter Postcode / ZIP",
                              pattern: {
                                value: /^\d{6}$/,
                                message: "Invalid Postcode / ZIP",
                              },
                            })}
                            maxLength={6}
                          />
                          {errors.pincode2 && (
                            <p className="error">{errors.pincode2.message}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="single-input-item">
                      <label for="ordernote">Order Note</label>
                      <textarea
                        name="ordernote"
                        id="ordernote"
                        cols="30"
                        rows="3"
                        placeholder="Notes about your order, e.g. special notes for delivery."
                      ></textarea>
                    </div> */}
                    <div
                      className="single-input-item d-flex float-end mt-4 mx-2 row"
                      style={{ width: "95%" }}
                    >
                      {/* <button
                        type="submit"
                        className="check-btn sqr-btn button"
                        // style={{ width: "250px" }}
                        onClick={handleScrollToDiv}
                      >
                        Next
                      </button> */}
                      <div className="col-md-4"></div>

                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        {!isLoading && (
                          <button
                            type="submit"
                            className="check-btn sqr-btn button w-100"
                            // style={{ width: "250px" }}
                            onClick={handleScrollToDiv}
                          >
                            Next
                          </button>
                        )}
                        {isLoading && (
                          <>
                            <button
                              type="button"
                              className="check-btn sqr-btn button w-100"
                              // style={{ width: "250px" }}
                            ></button>
                            <div className="loader-container">
                              <div className="loader "></div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            {/* <!-- Order Summary Details --> */}
            <div className="col-lg-4">
              <div className="order-summary-details card p-4 my-3 mx-3">
                <h2>Your Order Summary</h2>
                <div className="order-summary-content">
                  {/* <!-- Order Summary Table --> */}
                  <div className="order-summary-table table-responsive">
                    <ul
                      className="cart-list scroll-bar2"
                      style={{ display: "block" }}
                    >
                      {cartItemList?.map((item, i) =>
                        item.color?.quantity > 0 ? (
                          <li key={i} className="d-flex m-3">
                            <div
                              className="cart-img me-5"
                              style={{ width: "80px", height: "90px" }}
                            >
                              <Link to="javascript:void(0)">
                                <img
                                  src={item.color?.image_url?.split("@")[0]}
                                  className="w-100 h-100"
                                  alt="product-img"
                                />
                              </Link>
                            </div>
                            <div className="cart-info">
                              <h6>
                                {item.product.product_name} (
                                {item.color?.color.split(",")[0]})
                              </h6>
                              <div className="d-flex justify-content-between">
                                <div className="size">
                                  Size: {item?.color?.size.name}
                                </div>
                                <div className="size">
                                  Qty: {item?.quantity}
                                </div>
                                <div className="size">
                                  {currencySymbol === "₹" ? "₹" : "$"}
                                  {item.color?.price}
                                </div>
                              </div>
                              {/* <div className="float-start">
                                      {item.color?.price ? <span>{currencySymbol === "₹" ? "Rs." : "$"} {item.color?.price} * {item?.quantity}</span> : null }
                                    </div> */}
                              {/* <div className="float-end"> */}
                              {item.color?.price ? (
                                <span className="mt-0" style={{ color: "red" }}>
                                  {currencySymbol === "₹" ? "₹" : "$"}
                                  {(
                                    item.color?.price * item?.quantity
                                  ).toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </span>
                              ) : null}
                              {/* </div> */}
                            </div>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>
                </div>
                <div className="fs-6 fw-bold mt-3" ref={scrollToPayment}>
                  Subtotal : {currencySymbol === "₹" ? "₹" : "$"}
                  {parseFloat(totalValue).toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
              </div>

              {priceCalculation && (
                <div className="order-summary-details card p-4 my-3 mx-3">
                  <h2>Payment Details</h2>
                  <div className="order-summary-content">
                    <div
                      className="checkoutaccordion"
                      style={{ marginTop: "20px" }}
                      id="checkOutAccordion"
                    >
                      <h4>Apply Coupon</h4>
                    </div>

                    <form onSubmit={handleSubmitCoupon(handleCoupon)}>
                      <div className="row">
                        <div className="col-sm-7">
                          <div className="single-input-item mb-2 mx-2">
                            <div className="ms-5">
                              {isExploding && (
                                <ConfettiExplosion
                                  force={0.4}
                                  duration={2200}
                                  particleCount={200}
                                  width={600}
                                  // height={400}
                                  particleSize={7}
                                  style={{ marginLeft: "100px" }}
                                />
                              )}
                            </div>
                            <input
                              type="text"
                              id="street-address"
                              placeholder="Coupon Code"
                              {...register2("coupon", {
                                required: "Enter Coupon Code",
                              })}
                              maxLength={10}
                              // onChange={(e) => setCouponCode(e.target.value)}
                            />
                            {errors2.coupon && (
                              <p className="error ms-1">
                                {errors2.coupon.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-sm-5">
                          <div className="single-input-item mx-2">
                            <button
                              type="submit"
                              className="check-btn sqr-btn w-100"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>

                    {/* <!-- Order Summary Table --> */}
                    {priceCalculation && (
                      <div className="order-summary-table table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Gross Amount</th>
                              <th>
                                {currencySymbol === "₹" ? "₹" : "$"}
                                {(
                                  priceCalculation?.sub_total * 1
                                ).toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </th>
                            </tr>
                          </thead>
                          {/* <thead>
                            <tr>
                              <th>Tax</th>
                              <th>
                                {currencySymbol === "₹" ? "₹" : "$"}
                                {priceCalculation?.tax}
                              </th>
                            </tr>
                          </thead> */}
                          <thead>
                            <tr>
                              <th>Discount</th>
                              <th>
                                {currencySymbol === "₹" ? "₹" : "$"}
                                {(
                                  priceCalculation?.discount * 1
                                ).toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </th>
                            </tr>
                          </thead>
                          <thead>
                            <tr>
                              <th>Delivery Charges</th>
                              <th style={{ color: "green" }}>
                                {/* {currencySymbol === "₹" ? "₹" : "$"}
                                {(
                                  priceCalculation?.shipping_fee * 1
                                ).toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })} */}
                                Free
                              </th>
                            </tr>
                          </thead>

                          <thead>
                            <tr>
                              <th className="fw-bold fs-5">Order Total</th>
                              <th className="fw-bold fs-5">
                                {currencySymbol === "₹" ? "₹" : "$"}
                                {(
                                  priceCalculation?.sub_total -
                                  priceCalculation?.discount
                                ).toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    )}
                    {/* <!-- Order Payment Method --> */}
                    {priceCalculation && (
                      <div className="single-input-item mx-2">
                        {payment ? (
                          <button
                            type="submit"
                            className="check-btn sqr-btn w-100"
                            onClick={handlePayment}
                          >
                            Place Order
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="check-btn sqr-btn w-100"
                            style={{ background: "grey", cursor: "auto" }}
                          >
                            Place Order
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- checkout main wrapper end --> */}
    </div>
  );
}
