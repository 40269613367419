import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getCartItems,
  updateCartItems,
  deleteCartItem,
} from "../../services/Cart.service";
import { userCartDetails, userCartItems } from "../../redux/actions/Actions";
import { PATH } from "../../redux/constants/Constants";

export default function Cart() {
  const cartItemList = useSelector(
    (state) => state?.cartItems?.cartItems?.results
  );
  const totalValue = useSelector(
    (state) => state?.cartItems?.cartItems?.total_cost
  );
  const dispatch = useDispatch();
  // console.log(cartItemList);
  const currencySymbol = useSelector(
    (state) => state?.status?.restaurant?.currency_symbol
  );
  const token = useSelector((state) => state?.status?.restaurant?.Token);
  const cartId = useSelector(
    (state) => state?.cart?.cart?.results && state?.cart?.cart?.results[0]?.id
  );
  const userData = useSelector((state) => state?.user?.user);
  const navigate = useNavigate();

  const incrementQuantity = (index, item) => {
    // console.log(item);
    const updatedQuantity = cartItemList[index].quantity + 1;

    // console.log(updatedQuantity);
    if (updatedQuantity) {
      const data = {
        quantity: updatedQuantity,
        price: parseFloat(item.price),
        cart_id: cartId,
        product_id: item.product.product_id,
        color_id: item.color.color_id,
      };
      // console.log(data);
      updateCartItems(token, data, item.cart_item_id).then((res) => {
        // console.log(res);
        dispatch(userCartItems(res.data));
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    cartId &&
      getCartItems(token, cartId).then((res) => {
        res && dispatch(userCartItems(res.data));

        // console.log(res.data);
      });
    fetchAPIs();
  }, []);

  const fetchAPIs = () => {
    cartId &&
      getCartItems(token, cartId).then((res) => {
        dispatch(userCartItems(res.data));

        // console.log(res.data);
      });
  };

  // console.log(cartItemList);

  const decrementQuantity = (index, item) => {
    const updatedQuantity =
      cartItemList[index].quantity > 1 ? cartItemList[index].quantity - 1 : 1;
    // handleQuantityChange(index, updatedQuantity);
    // console.log(updatedQuantity);

    if (updatedQuantity) {
      const data = {
        quantity: updatedQuantity,
        price: item.price,
        cart_id: cartId,
        product_id: item.product.product_id,
        color_id: item.color.color_id,
      };
      updateCartItems(token, data, item.cart_item_id).then((res) => {
        // console.log(res.data);
        dispatch(userCartItems(res.data));
      });
    }
  };

  const handleItemDelete = (cart_item_id) => {
    // console.log(cart_item_id);
    deleteCartItem(token, cart_item_id).then((res) => {
      cartId &&
        getCartItems(token, cartId).then((res) => {
          dispatch(userCartItems(res.data));
          toast.error("Product removed from your cart!", {
            autoClose: 1500,
            style: {
              backgroundColor: "black",
              color: "white",
            },
            containerId: "cart",
          });
        });
    });
  };

  // console.log(cartItemList);

  const handleCheckout = () => {
    if (!Array.isArray(userData) && userData) {
      navigate(`/checkout`);
    } else {
      toast.warning("Please log in to continue!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
        containerId: "cart",
      });

      setTimeout(() => {
        navigate(`/login`);
      }, 3000);
    }
  };

  return (
    <div style={{ minHeight: "800px" }}>
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
        containerId="cart"
      />

      {/* <!-- cart main wrapper start --> */}
      <div className="cart-main-wrapper section-padding mx-3">
        {cartItemList?.length > 0 ? (
          <div className="container custom-container">
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Cart Table Area --> */}
                <div className="cart-table table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="">#</th>
                        <th className="pro-thumbnail">Thumbnail</th>
                        <th className="pro-title">Product</th>
                        <th className="">Size</th>
                        <th className="pro-price">Price</th>
                        <th className="pro-quantity">Quantity</th>
                        <th className="pro-subtotal">Total</th>
                        <th className="pro-remove">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItemList?.map((item, i) => (
                        <tr key={i}>
                          <td className="">{i + 1}</td>
                          <td className="pro-thumbnail">
                            <img
                              className="img-fluid"
                              src={item.color?.image_url?.split("@")[0]}
                              alt="Product-Img"
                            />
                          </td>
                          <td className="pro-title">
                            {item.product.product_name} (
                            {item.color?.color.split(",")[0]})
                            {/* {item.product.product_name}  */}
                            <br />
                            <h6
                              style={{
                                color: "red",
                                fontWeight: 700,
                                fontSize: "12px",
                              }}
                              className="mt-2"
                            >
                              {item?.color?.quantity === item?.quantity &&
                              item?.color?.quantity > 0
                                ? "You reached the maximum quantity available."
                                : item?.color?.quantity < 6 &&
                                  item?.color?.quantity > 0
                                ? "Only " +
                                  item?.color?.quantity +
                                  " left in stock."
                                : item?.color?.quantity > 5 &&
                                  item?.color?.quantity < 10
                                ? "Only few left in stock."
                                : ""}
                            </h6>
                          </td>
                          <td className="pro-title">
                            {item?.color?.size.name}
                          </td>
                          <td className="pro-price">
                            <span>
                              {currencySymbol === "₹" ? "₹" : "$"}
                              {(item.color?.price * 1).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </span>
                          </td>
                          <td className="pro-quantity">
                            {item?.color?.quantity === 0 ? (
                              <div style={{ color: "red" }}>Out of stock</div>
                            ) : (
                              <div className="pro-qty">
                                <span
                                  className="dec qtybtn"
                                  onClick={() => decrementQuantity(i, item)}
                                >
                                  -
                                </span>
                                <input
                                  type="number"
                                  value={item?.quantity}
                                  min={1}
                                  // onChange={(e) =>
                                  //   handleQuantityChange(
                                  //     i,
                                  //     parseFloat(e.target.value)
                                  //   )
                                  // }
                                />
                                {item?.color?.quantity > item?.quantity ? (
                                  <span
                                    className="inc qtybtn"
                                    onClick={() => incrementQuantity(i, item)}
                                  >
                                    +
                                  </span>
                                ) : (
                                  <span
                                    className="inc qtybtn"
                                    // onClick={() => incrementQuantityExceed()}
                                  >
                                    +
                                  </span>
                                )}
                              </div>
                            )}
                          </td>
                          <td className="pro-subtotal">
                            <span>
                              {currencySymbol === "₹" ? "₹" : "$"}
                              {(
                                parseFloat(item?.color?.price) * item?.quantity
                              ).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </span>
                          </td>
                          <td
                            className="pro-remove"
                            onClick={() => handleItemDelete(item.cart_item_id)}
                          >
                            <Link to="javascript:void(0)">
                              <i className="fa fa-trash-o"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {totalValue > 0 ? (
              <div className="d-flex justify-content-end">
                <div className="mt-3 flex-end cart-subtotal">
                  <div className="cart-calculator-wrapper mt-0 px-0">
                    <div className="cart-calculate-items">
                      {/* <h3>Cart Totals</h3> */}
                      <div className="table-responsive">
                        <table className="table">
                          <tr>
                            <td>Subtotal</td>
                            <td>
                              {currencySymbol === "₹" ? "₹" : "$"}
                              {parseFloat(totalValue).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                          {/* <tr className="total">
                          <td>Discount</td>
                          <td className="total-amount">- {currencySymbol === "₹" ? "Rs." : "$"}{" "}70.00</td>
                        </tr> */}
                          {/* <tr>
                          <td>Shipping</td>
                          <td>{currencySymbol === "₹" ? "Rs." : "$"}{" "}70</td>
                        </tr> */}
                          {/* <tr className="total">
                          <td>Total</td>
                          <td className="total-amount">{currencySymbol === "₹" ? "Rs." : "$"} {parseFloat(totalValue-70).toFixed(2)}</td>
                        </tr> */}
                        </table>
                      </div>
                    </div>
                    <Link
                      to="javascript:void(0)"
                      className="sqr-btn1 d-block"
                      onClick={handleCheckout}
                    >
                      Proceed To Checkout
                    </Link>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="container custom-container">
            <div className="row">
              {/* <div className="col-lg-12 text-center">Items Not Available</div> */}
              <div className="col-lg-12 text-center">
                <img
                  src={`${PATH}/assets/img/bamboosa/emptycart.png`}
                  alt="empty-cart"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <!-- cart main wrapper end --> */}
    </div>
  );
}
