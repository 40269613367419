import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ minHeight: "800px" }}>
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
      />

      <div className="about-us-wrap">
        <div
          style={{ fontWeight: 900 }}
          className="d-flex justify-content-center"
        >
          <br />
          <h2 className="mb-4">About Us</h2>
        </div>

        <p style={{ textAlign: "justify" }}>
          <h6 style={{ fontWeight: "600", fontSize: "17px" }} className="my-2">
            Welcome to BambooSA!
          </h6>
          At BambooSA, we are passionate about sustainability, comfort, and
          style. Founded with a vision to revolutionize the fashion industry, we
          offer eco-friendly bamboo-made clothing that is as kind to the
          environment as it is to your skin.
          <h6
            style={{ fontWeight: "600", fontSize: "20px" }}
            className="mb-2 mt-4"
          >
            Our Story
          </h6>
          The journey of BambooSA began with a simple idea: to create clothing
          that embodies the harmony between nature and modern living. We
          discovered that bamboo, a fast-growing and renewable resource, could
          be transformed into luxuriously soft, breathable, and durable fabrics.
          This realization led us to develop a range of clothing that combines
          eco-conscious materials with contemporary designs.
          <h6
            style={{ fontWeight: "600", fontSize: "20px" }}
            className="mb-2 mt-4"
          >
            Our Mission
          </h6>
          Our mission is to provide high-quality, sustainable clothing options
          that reduce environmental impact and promote a greener future. We
          believe that fashion should not come at the expense of our planet. By
          choosing BambooSA, you are not only investing in superior comfort and
          style but also contributing to a more sustainable world.
          <h6
            style={{ fontWeight: "600", fontSize: "20px" }}
            className="mb-2 mt-4"
          >
            Why Bamboo?
          </h6>
          <ul
            style={{ textAlign: "justify", paddingLeft: "50px" }}
            className="product-list-disc"
          >
            <li>
              <strong>Eco-Friendly:</strong> Bamboo is one of the most
              sustainable plants on the planet, growing rapidly without the need
              for pesticides or fertilizers.
            </li>
            <li>
              <strong>Biodegradable:</strong> Bamboo fibers naturally decompose,
              making them an excellent choice for environmentally-conscious
              consumers.
            </li>
            <li>
              <strong>Hypoallergenic:</strong> Bamboo fabric is gentle on
              sensitive skin, making it perfect for everyone, including those
              with allergies.
            </li>
            <li>
              <strong>Breathable and Moisture-Wicking:</strong> Bamboo clothing
              keeps you cool and dry, offering superior comfort in all weather
              conditions.
            </li>
          </ul>
          <h6
            style={{ fontWeight: "600", fontSize: "20px" }}
            className="mb-2 mt-4"
          >
            Our Products
          </h6>
          At BambooSA, we offer a wide range of clothing for men and women. From everyday essentials to stylish statement pieces, our
          collection includes:
          <ul
            style={{ textAlign: "justify", paddingLeft: "50px" }}
            className="product-list-disc"
          >
            <li>
              <strong>T-shirts</strong>
            </li>
            {/* <li>
              <strong>Shirts</strong>
            </li>
            <li>
              <strong>Dresses</strong>
            </li>
            <li>
              <strong>Activewear</strong>
            </li>
            <li>
              <strong>Loungewear</strong>
            </li>
            <li>
              <strong>Accessories</strong>
            </li> */}
          </ul>{" "}
          Every item is meticulously crafted to ensure the highest standards of
          quality, comfort, and sustainability.
          <h6
            style={{ fontWeight: "600", fontSize: "20px" }}
            className="mb-2 mt-4"
          >
            Our Commitment
          </h6>
          We are dedicated to ethical practices and transparency in all aspects
          of our business. From sourcing raw materials to the manufacturing
          process, we ensure that every step is environmentally responsible and
          socially fair. Our partnerships with eco-friendly suppliers and
          ethical factories reflect our commitment to making a positive impact
          on the planet and the people involved in our supply chain.
          <h6
            style={{ fontWeight: "600", fontSize: "20px" }}
            className="mb-2 mt-4"
          >
            Join Us on Our Journey
          </h6>
          We invite you to explore our collection and join us in our mission to
          make fashion more sustainable. Together, we can create a better future
          for our planet, one bamboo garment at a time.
          <h6 style={{ fontWeight: "600", fontSize: "15px" }} className="my-3">
            <em>Thank you for choosing BambooSA. Wear green, feel great!</em>
          </h6>
        </p>
      </div>
    </div>
  );
}
