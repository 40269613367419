import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function PrivacyPolicy() {
  const aboutUs = useSelector((state) => state?.status?.restaurant?.about_us);
  const address = useSelector((state) => state?.status?.restaurant?.address);
  const city = useSelector((state) => state?.status?.restaurant?.city);
  const state = useSelector((state) => state?.status?.restaurant?.state);
  const country = useSelector((state) => state?.status?.restaurant?.country);
  const zip = useSelector((state) => state?.status?.restaurant?.zip);
  const email = useSelector((state) => state?.status?.restaurant?.support_email);
  const phone = useSelector(
    (state) => state?.status?.restaurant?.support_phone_number
  );
  const working_hours = useSelector(
    (state) => state?.status?.restaurant?.working_hours
  );

  const newAddress =
    address + ", " + city + ", " + state + ", " + country + ", " + zip;
  // console.log(newAddress);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pb-5" style={{ minHeight: "800px" }}>
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
      />

      <div className="pb-5">
        <div className="about-us-wrap">
          <div
            style={{ fontWeight: 900 }}
            className="d-flex justify-content-center"
          >
            <br />
            <h2 className="mb-4">Privacy Policy</h2>
          </div>
          <h6 style={{ fontWeight: "600", fontSize: "17px" }} className="my-2">
            Welcome to BambooSA!
          </h6>
          <p>
            At BambooSA, we are committed to protecting your privacy and
            ensuring the security of your personal information. This Privacy
            Policy outlines how we collect, use, disclose, and safeguard your
            data when you visit our website or interact with our services. By
            using our website or providing us with your personal information,
            you consent to the terms outlined in this Privacy Policy.
          </p>

          <br />
          <h5>1. Information We Collect</h5>
          <p>
            <strong>Personal Information:</strong> When you make a purchase or
            create an account on our website, we may collect personal
            information such as your name, email address, shipping address,
            billing address, and payment details.
            <br />
            <strong>Usage Data:</strong> We may also collect non-personal
            information about your interaction with our website, including your
            IP address, browser type, device information, and pages visited.
          </p>

          <br />
          <h5>2. How We Use Your Information</h5>
          <p>
            <strong>To Process Orders:</strong> We use your personal information
            to process and fulfill your orders, including shipping and delivery.
            <br />
            <strong>To Communicate:</strong> We may use your contact information
            to communicate with you about your orders, promotions, updates, and
            customer service inquiries.
            <br />
            <strong>To Improve Our Services:</strong> We analyze usage data to
            improve our website, products, and services, and to tailor our
            marketing efforts to better meet your needs.
            <br />
            <strong>To Prevent Fraud:</strong> We may use your information to
            detect and prevent fraudulent activities or unauthorized
            transactions.
          </p>

          <br />
          <h5>3. Data Security</h5>
          <p>
            We implement appropriate security measures to protect your personal
            information from unauthorized access, disclosure, alteration, or
            destruction.
            <br />
            We use secure payment gateways and encryption technologies to ensure
            the confidentiality of your payment information.
          </p>
        </div>
      </div>
    </div>
  );
}
