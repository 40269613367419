// reducers.js
import { REMEMBER_ME, FORGET_ME } from "../constants/Constants";

const initialState = {
  rememberedEmail: "",
  rememberedPassword: "",
};

const rememberMeReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMEMBER_ME:
      return {
        ...state,
        rememberedEmail: action.payload.email,
        rememberedPassword: action.payload.password,
      };
    case FORGET_ME:
      return {
        ...state,
        rememberedEmail: "",
        rememberedPassword: "",
      };
    default:
      return state;
  }
};

export default rememberMeReducer;
