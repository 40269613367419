import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import axios from "axios"; // Import axios for making HTTP requests
import { useForm } from "react-hook-form";
import {
  userLogin,
  userForgotPassword,
  userGuestLogin,
  guestOtpVerification,
} from "../../services/UserLogin.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { PATH, RESTAURANT_ID } from "../../redux/constants/Constants";
import {
  getUserTokenAction,
  userCartDetails,
  userCartItems,
  rememberMe,
  forgetMe,
  clearPreLoginCart,
} from "../../redux/actions/Actions";
import {
  addToCart,
  cartAvailability,
  createCart,
  getCartItems,
} from "../../services/Cart.service";

export default function Login() {
  const [countryCode, setCountryCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState(null);
  const [showGuestLoginModal, setShowGuestLoginModal] = useState(false);
  const [showOTPVerificationModal, setShowOTPVerificationModal] =
    useState(false);
  const [otpValues, setOtpValues] = useState({});
  const userData = useSelector((state) => state?.user?.user?.token);
  const cartId = useSelector(
    (state) => state?.cart?.cart?.results && state?.cart?.cart?.results[0]?.id
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state?.status?.restaurant?.Token);
  let intlTelInputRef = useRef(null);
  const currencySymbol = useSelector(
    (state) => state?.status?.restaurant?.currency_symbol
  );

  const { rememberedEmail, rememberedPassword } = useSelector(
    (state) => state?.rememberMe
  );

  const [isRememberedMe, setIsRememberedMe] = useState(
    rememberedEmail ? true : false
  );

  // console.log(rememberedEmail, rememberedPassword);

  const preLoginCount = useSelector(
    (state) => state?.preLoginCart?.preLoginCart?.length
  );
  // console.log(preLoginCount);
  const preLoginCart = useSelector(
    (state) => state?.preLoginCart?.preLoginCart
  );
  // console.log(preLoginCart);

  const {
    register,
    reset,
    handleSubmit: handleSubmitSignIn,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (rememberedEmail && rememberedPassword) {
      setValue("email", rememberedEmail);
      setValue("password", rememberedPassword);
      setIsRememberedMe(true);
    }
  }, [rememberedEmail, rememberedPassword, setValue]);

  useEffect(() => {
    window.scrollTo(0, 0);

    cartId &&
      getCartItems(token, cartId).then((res) => {
        res && dispatch(userCartItems(res?.data));

        // console.log(res?.data);
      });
    fetchAPIs();
  }, []);

  const fetchAPIs = () => {
    cartId &&
      getCartItems(token, cartId).then((res) => {
        dispatch(userCartItems(res?.data));

        // console.log(res?.data);
      });
    // Get user's location using Geolocation API
    // navigator.geolocation.getCurrentPosition(
    //   (position) => {
    //     const { latitude, longitude } = position.coords;
    //     // Make a request to a reverse geocoding service to get the country code
    //     axios
    //       .get(
    //         `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
    //       )
    //       .then((response) => {
    //         // Extract the country code from the response
    //         const countryCode = response.data.countryCode.toUpperCase(); // Ensure uppercase
    //         setCountryCode(countryCode);
    //       })
    //       .catch((error) => {
    //         console.error("Error fetching country code:", error);
    //       });
    //   },
    //   (error) => {
    //     console.error("Error getting user location:", error);
    //   }
    // );
  };

  // console.log(countryCode);

  const {
    register: register2,
    handleSubmit: handleSubmitForgotUsername,
    formState: { errors: errors2 },
  } = useForm();

  const {
    register: register3,
    handleSubmit: handleSubmitForgotPassword,
    reset: reset3,
    formState: { errors: errors3 },
  } = useForm();

  const {
    register: register4,
    handleSubmit: handleSubmitGuestLogin,
    setError: setError4,
    clearErrors: clearErrors4,
    setValue: setValue4,
    reset: reset4,
    formState: { errors: errors4 },
  } = useForm();

  const {
    register: register5,
    handleSubmit: handleSubmitOTPVerification,
    reset: reset5,
    formState: { errors: errors5 },
  } = useForm();

  const signIn = (item) => {
    if (item.rememberMe) {
      dispatch(rememberMe(item.email, item.password));
    } else {
      dispatch(forgetMe());
    }

    // Call your login service here
    // console.log(data);

    // console.log(item);

    const data = {
      username: item.email,
      password: item.password,
      restaurant_id: RESTAURANT_ID,
    };

    JSON.stringify(data);

    // console.log(data);

    userLogin(token, data).then((res) => {
      // console.log(res);

      if (res.status === 200) {
        // console.log(res?.data);
        const userData = res?.data;

        dispatch(getUserTokenAction(res?.data));

        toast.success("Login successful!", {
          autoClose: 1500,
          style: {
            backgroundColor: "black",
            color: "white",
          },
          containerId: "login",
        });

        if (userData) {
          cartAvailability(token, userData.id).then((res) => {
            // console.log(res?.data);
            // console.log(res?.data.results?.length < 1);
            if (res?.data.results?.length < 1) {
              const data = {
                customer_id: userData.id,
                restaurant: RESTAURANT_ID,
              };

              createCart(token, data).then((res) => {
                // console.log(res?.data);

                cartAvailability(token, userData.id).then((res) => {
                  // console.log(res?.data);
                  dispatch(userCartDetails(res?.data));

                  if (preLoginCount > 0) {
                    const addToCartPromises = preLoginCart?.map((item) => {
                      let data = {
                        price: item.price,
                        quantity: item.quantity,
                        cart_id: res?.data?.results?.[0]?.id,
                        product_id: item.product_id,
                        color_id: item.color_id,
                      };

                      // console.log(data);

                      return addToCart(token, data).then(async (res) => {
                        return res?.data;
                      });
                    });

                    Promise.all(addToCartPromises)
                      .then((results) => {
                        results.forEach((resData) => {
                          // console.log(resData);
                          dispatch(userCartItems(resData));
                        });

                        // After all items are added, clear the pre-login cart
                        dispatch(clearPreLoginCart());
                      })
                      .catch((error) => {
                        console.error("Error adding to cart:", error);
                      });
                  }

                  getCartItems(token, res?.data.results[0].id).then((res) => {
                    // console.log(res?.data);
                    dispatch(userCartItems(res?.data));
                  });
                });
              });
            } else {
              cartAvailability(token, userData.id).then((res) => {
                // console.log(res?.data);
                dispatch(userCartDetails(res?.data));

                if (preLoginCount > 0) {
                  const addToCartPromises = preLoginCart?.map((item) => {
                    let data = {
                      price: item.price,
                      quantity: item.quantity,
                      cart_id: res?.data?.results?.[0]?.id,
                      product_id: item.product_id,
                      color_id: item.color_id,
                    };

                    // console.log(data);

                    return addToCart(token, data).then(async (res) => {
                      return res?.data;
                    });
                  });

                  Promise.all(addToCartPromises)
                    .then((results) => {
                      results.forEach((resData) => {
                        // console.log(resData);
                        dispatch(userCartItems(resData));
                      });

                      // After all items are added, clear the pre-login cart
                      dispatch(clearPreLoginCart());
                    })
                    .catch((error) => {
                      console.error("Error adding to cart:", error);
                    });
                }

                getCartItems(token, res?.data.results[0].id).then((res) => {
                  // console.log(res?.data);
                  dispatch(userCartItems(res?.data));
                });
              });

              // console.log(res?.data);
              dispatch(userCartDetails(res?.data));
            }
          });
        }
        // else {
        //   toast.warning("Please Login First...!", {
        //     autoClose: 1500,
        //     style: {
        //       backgroundColor: "black",
        //       color: "white",
        //     },
        //   });

        //   setTimeout(() => {
        //     navigate("/login");
        //   }, 3000);
        // }

        reset();

        setTimeout(() => {
          navigate(`/`);
        }, 3000);
      } else if (res.status === 403) {
        toast.warning(
          <React.Fragment>
            Your email verification is pending!<br />
            Please Check your inbox to verify.
          </React.Fragment>,
          {
            autoClose: 5000,
            style: {
              backgroundColor: "black",
              color: "white",
            },
            containerId: "login",
          }
        );
      } else {
        toast.error(
          <React.Fragment>
            Login failed! <br /> Please check your credentials and try again.
          </React.Fragment>,
          {
            autoClose: 5000,
            style: {
              backgroundColor: "black",
              color: "white",
              // width: "350px",
              // marginLeft: "-20px",
            },
            containerId: "login",
          }
        );
      }
    });
  };

  const forgotUsername = (item) => {
    // console.log(item);
  };

  const forgotPassword = (item) => {
    // console.log(item);
    const data = {
      username: item.email3,
    };

    userForgotPassword(token, data).then((res) => {
      // console.log(res?.data);
      if (res?.data) {
        toast.success(
          <React.Fragment>
            Password reset successful! <br />
            Please check your email for the new password.
          </React.Fragment>,
          {
            autoClose: 4000,
            style: {
              backgroundColor: "black",
              color: "white",
            },
            containerId: "login",
          }
        );

        document.getElementById("forgotPasswordCloseModal").click();
      } else {
        toast.error(
          <React.Fragment>
            The provided email is not registered!<br />
            Please check and try again.
          </React.Fragment>,
          {
            autoClose: 4000,
            style: {
              backgroundColor: "black",
              color: "white",
            },
            containerId: "login",
          }
        );
      }
    });
  };

  const inputMobileNumber = () => {
    const mobileNumberPattern = /^[0-9]{10}$/;
    const mobileNumber = intlTelInputRef.current?.state.value;
    const code = intlTelInputRef.current?.state.title.split(" ")[2];
    if (mobileNumberPattern.test(mobileNumber)) {
      clearErrors4("mobileNumber");
      setValue4("mobileNumber", code + mobileNumber);
      setMobileNumber(code + mobileNumber);
    } else {
      setError4("mobileNumber", {
        type: "manual",
        message: "Enter Valid Mobile Number",
      });
    }

    if (!mobileNumber) {
      setError4("mobileNumber", {
        type: "manual",
        message: "Enter Mobile Number",
      });
    }

    // console.log("Mobile Number", code + mobileNumber);
  };

  const guestLogin = (item) => {
    reset5();

    const data = {
      phone_number: item.mobileNumber,
    };

    // setShowOTPVerificationModal(true);
    // setShowGuestLoginModal(false);

    userGuestLogin(token, data).then((res) => {
      // console.log(res);
      if (res.status === 200) {
        setShowOTPVerificationModal(true);
        setShowGuestLoginModal(false);
      }
    });
  };

  const otpVerification = (item) => {
    // console.log(item);

    const data = {
      verification_code: item.otp1 + item.otp2 + item.otp3 + item.otp4,
      phone_number: mobileNumber,
    };

    // console.log(data);

    guestOtpVerification(token, data).then((res) => {
      // console.log(res);
      if (res.status === 200) {
        toast.success("OTP verified successfully!", {
          autoClose: 2500,
          style: {
            backgroundColor: "black",
            color: "white",
          },
          containerId: "login",
        });
        dispatch(getUserTokenAction(res?.data));
        setShowOTPVerificationModal(false);
        setShowGuestLoginModal(false);
        document.getElementById("guestLoginCloseModal").click();

        setTimeout(() => {
          navigate(`/`);
        }, 3000);
      } else {
        toast.error(
          <React.Fragment>
            OTP verification failed! <br />
            Please try again.
          </React.Fragment>,
          {
            autoClose: 2500,
            style: {
              backgroundColor: "black",
              color: "white",
            },
            containerId: "login",
          }
        );
      }
    });
  };

  const changeNumber = () => {
    setShowOTPVerificationModal(false);
    setShowGuestLoginModal(true);
  };

  return (
    <div
      className="login-register-wrapper section-padding"
      // style={{ height: "610px" }}
      style={{ minHeight: "800px" }}
    >
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
        containerId="login"
      />
      <div className="container py-5">
        <div className="member-area-from-wrap">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6">
              {/* <!-- Login Content Start --> */}
              <div>
                <div className="login-reg-form-wrap">
                  <h2>Sign In</h2>
                  <form
                    action="#"
                    method="post"
                    onSubmit={handleSubmitSignIn(signIn)}
                  >
                    <div className="single-input-item mb-3 mx-2">
                      <input
                        type="text"
                        placeholder="Enter Email"
                        {...register("email", {
                          required: "Enter Email",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Invalid Email Address",
                          },
                        })}
                        maxLength={50}
                      />
                      {errors.email && (
                        <p className="error">{errors.email.message}</p>
                      )}
                    </div>
                    {/* <div className="single-input-item">
                      <div className="login-reg-form-meta d-flex align-items-center justify-content-end">
                        <Link
                          to="javascript:void(0)"
                          className="forget-pwd"
                          data-bs-toggle="modal"
                          data-bs-target="#forgotUsernameModal"
                        >
                          Forgot Username?
                        </Link>
                      </div>
                    </div> */}
                    <div className="single-input-item mx-2">
                      <input
                        className="mt-2"
                        type="password"
                        placeholder="Enter Password"
                        {...register("password", {
                          required: "Enter Password",
                        })}
                        maxLength={20}
                      />
                      {errors.password && (
                        <p className="error">{errors.password.message}</p>
                      )}
                    </div>
                    <div className="single-input-item mx-2">
                      <div className="login-reg-form-meta d-flex align-items-center justify-content-between">
                        <div className="remember-meta">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input mt-3"
                              id="rememberMe"
                              {...register("rememberMe")}
                              checked={isRememberedMe}
                              onChange={() =>
                                setIsRememberedMe(!isRememberedMe)
                              }
                            />
                            <label
                              className="form-check-label mt-3"
                              for="rememberMe"
                            >
                              Remember Me
                            </label>
                          </div>
                        </div>
                        <Link
                          to="javascript:void(0)"
                          className="forget-pwd"
                          data-bs-toggle="modal"
                          data-bs-target="#forgotPasswodModal"
                        >
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    <div className="single-input-item mx-2">
                      <button className="sqr-btn w-100" type="submit">
                        Login
                      </button>
                    </div>

                    <div className="single-input-item mx-2">
                      <div className="login-reg-form-meta d-flex align-items-center justify-content-between">
                        <Link
                          to="javascript:void(0)"
                          className="forget-pwd"
                          data-bs-toggle="modal"
                          data-bs-target="#guestLoginModal"
                          // data-bs-target="#otpVerificationModal"
                          onClick={() => {
                            setShowGuestLoginModal(true); // Close the guest login modal
                            setShowOTPVerificationModal(false); // Open the OTP verification modal
                          }}
                          data-bs-dismiss="modal"
                        >
                          <b>Guest Sign In</b>
                        </Link>
                        <Link
                          to={`/register`}
                          className="forget-pwd"
                          data-bs-dismiss="modal"
                        >
                          <b>Not a Member? Sign Up</b>
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* <!-- Login Content End --> */}
            </div>
          </div>
        </div>
      </div>

      {/* Forgot Username Modal */}
      <div
        className="modal fade mt-5 ms-2"
        id="forgotUsernameModal"
        tabIndex="-1"
        aria-labelledby="forgotUsernameModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mt-5">
          <div className="modal-body">
            <div>
              <div className="login-reg-form-wrap">
                <div className="d-flex justify-content-between mb-0">
                  <h2>Reset Username</h2>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="forgotUsernameCloseModal"
                  ></button>
                </div>
                <form
                  action="#"
                  method="post"
                  onSubmit={handleSubmitForgotUsername(forgotUsername)}
                >
                  <label>Email</label>{" "}
                  <i className="text-danger">
                    <b>*</b>
                  </i>
                  <div className="single-input-item mb-5 mt-1 mx-2">
                    <input
                      type="text"
                      placeholder="Enter Email"
                      {...register2("email2", {
                        required: "Enter Email",
                      })}
                      maxLength={50}
                    />
                    {errors2.email && (
                      <p className="error">{errors2.email.message}</p>
                    )}
                  </div>
                  <div className="single-input-item mx-2">
                    <button className="sqr-btn w-100" type="submit">
                      Reset
                    </button>
                  </div>
                  <div className="single-input-item mx-2">
                    <div className="login-reg-form-meta d-flex align-items-center justify-content-end">
                      <Link
                        to={`/register`}
                        className="forget-pwd"
                        data-bs-dismiss="modal"
                      >
                        <b>Not a Member? Sign Up</b>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Forgot Password Modal */}
      <div
        className="modal fade mt-5 ms-2"
        id="forgotPasswodModal"
        tabIndex="-1"
        aria-labelledby="forgotPasswodModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mt-5">
          <div className="modal-body">
            <div>
              <div className="login-reg-form-wrap">
                <div className="d-flex justify-content-between mb-0">
                  <h2>Reset Password</h2>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="forgotPasswordCloseModal"
                  ></button>
                </div>
                <form
                  action="#"
                  method="post"
                  onSubmit={handleSubmitForgotPassword(forgotPassword)}
                >
                  <label>Email</label>{" "}
                  <i className="text-danger">
                    <b>*</b>
                  </i>
                  <div className="single-input-item mb-5 mt-1 mx-2">
                    <input
                      type="text"
                      placeholder="Enter Email"
                      {...register3("email3", {
                        required: "Enter Email",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "Invalid Email Address",
                        },
                      })}
                      maxLength={50}
                    />
                    {errors3.email3 && (
                      <p className="error">{errors3.email3.message}</p>
                    )}
                  </div>
                  <div className="single-input-item mx-2">
                    <button className="sqr-btn w-100" type="submit">
                      Reset
                    </button>
                  </div>
                  <div className="single-input-item mx-2">
                    <div className="login-reg-form-meta d-flex align-items-center justify-content-end">
                      <Link
                        to={`/register`}
                        className="forget-pwd"
                        data-bs-dismiss="modal"
                      >
                        <b>Not a Member? Sign Up</b>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Guest Login Modal */}
      <div
        className={`modal fade mt-5 ms-2 ${showGuestLoginModal ? "show" : ""}`}
        id="guestLoginModal"
        tabIndex="-1"
        aria-labelledby="guestLoginModalLabel"
        aria-hidden={!showGuestLoginModal}
      >
        <div className="modal-dialog mt-5">
          <div className="modal-body">
            <div>
              <div className="login-reg-form-wrap">
                <div className="d-flex justify-content-between mb-0">
                  <h2>Guest Sign In</h2>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="guestLoginCloseModal"
                    onClick={() => setShowGuestLoginModal(false)}
                  ></button>
                </div>
                <form
                  action="#"
                  method="post"
                  onSubmit={handleSubmitGuestLogin(guestLogin)}
                >
                  <div className="d-flex justify-content-center">
                    <label className="mb-3">
                      <b>Sign in as a Guest User!</b>
                    </label>{" "}
                  </div>
                  <label>Mobile Number</label>{" "}
                  <i className="text-danger">
                    <b>*</b>
                  </i>
                  <div className="single-input-item mb-5 mt-1 mx-2">
                    {/* <IntlTelInput
                      {...register4("mobileNumber", {
                        required: "Enter Mobile Number",
                      })}
                      ref={intlTelInputRef}
                      containerClassName="intl-tel-input"
                      inputClassName="form-control"
                      defaultCountry={currencySymbol === "₹" ? "in" : "us"}
                      autoPlaceholder="polite"
                      onlyCountries={["in", "us"]}
                      onPhoneNumberChange={() => inputMobileNumber()}
                    /> */}
                    <IntlTelInput
                      {...register4("mobileNumber", {
                        required: "Enter Mobile Number",
                      })}
                      ref={intlTelInputRef}
                      containerClassName="intl-tel-input"
                      inputClassName="form-control"
                      defaultCountry={currencySymbol === "₹" ? "in" : "us"}
                      autoPlaceholder=""
                      placeholder="Mobile Number"
                      onlyCountries={["in", "us"]}
                      onPhoneNumberChange={() => inputMobileNumber()}
                      separateDialCode={true}
                    />
                    {errors4.mobileNumber && (
                      <p className="error">{errors4.mobileNumber.message}</p>
                    )}
                  </div>
                  <div className="single-input-item mx-2">
                    <button className="sqr-btn w-100" type="submit">
                      Request OTP
                    </button>
                  </div>
                  <div className="single-input-item mx-2">
                    <div className="login-reg-form-meta d-flex align-items-center justify-content-end">
                      <Link
                        to={`/register`}
                        className="forget-pwd"
                        data-bs-dismiss="modal"
                      >
                        <b>Not a Member? Sign Up</b>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* OTP Varification */}
      <div
        className={`modal fade mt-5 ${showOTPVerificationModal ? "show" : ""}`}
        id="otpVerificationModal"
        tabIndex="-1"
        aria-labelledby="otpVerificationModalLabel"
        // aria-hidden={!showOTPVerificationModal}
        data-bs-dismiss="modal"
      >
        <div className="modal-dialog mt-5">
          <div className="modal-body">
            <div>
              <div className="login-reg-form-wrap">
                <div className="d-flex justify-content-between mb-0">
                  <h2>OTP Verification</h2>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="otpVerificationCloseModal"
                    onClick={() => {
                      setShowOTPVerificationModal(false);
                      setShowGuestLoginModal(false);
                      document.getElementById("guestLoginCloseModal").click();
                    }}
                  ></button>
                </div>
                <form
                  action="#"
                  method="post"
                  onSubmit={handleSubmitOTPVerification(otpVerification)}
                >
                  <div className="d-flex justify-content-center">
                    <label className="mb-1">
                      <b>Please enter the OTP sent to {mobileNumber}</b>
                    </label>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="javascript:void(0)"
                      className="forget-pwd"
                      // data-bs-dismiss="modal"
                      onClick={() => changeNumber()}
                    >
                      <b>Change Number</b>
                    </Link>
                  </div>

                  {/* <div className="single-input-item mb-5 mt-1">
              <input type="text" placeholder="Enter OTP" {...register5("otp", {
                required: "Enter OTP",
              })} />
              {errors5.otp && (
                <p className="error">
                  {errors5.otp.message}
                </p>
              )}
            </div> */}
                  <div className="mb-5 mt-1 row d-flex justify-content-center otp-responsive">
                    <div className="col-lg-2">
                      <div className="single-input-item mx-2">
                        <input
                          className="otp"
                          id="otp1"
                          type="text"
                          {...register5("otp1", {
                            required: true,
                            maxLength: 1,
                          })}
                          onInput={() =>
                            document.getElementById("otp2").focus()
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="single-input-item mx-2">
                        <input
                          className="otp"
                          id="otp2"
                          type="text"
                          {...register5("otp2", {
                            required: true,
                            maxLength: 1,
                          })}
                          onInput={() =>
                            document.getElementById("otp3").focus()
                          }
                        />
                      </div>
                    </div>

                    <div className="col-lg-2">
                      <div className="single-input-item mx-2">
                        <input
                          className="otp"
                          id="otp3"
                          type="text"
                          {...register5("otp3", {
                            required: true,
                            maxLength: 1,
                          })}
                          onInput={() =>
                            document.getElementById("otp4").focus()
                          }
                        />
                      </div>
                    </div>

                    <div className="col-lg-2">
                      <div className="single-input-item mx-2">
                        <input
                          className="otp"
                          id="otp4"
                          type="text"
                          {...register5("otp4", {
                            required: true,
                            maxLength: 1,
                          })}
                          onInput={() => document.getElementById("otp4").blur()}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="single-input-item ms-5">
                    <div className="ms-3">
                      {errors5.otp1 &&
                        errors5.otp2 &&
                        errors5.otp3 &&
                        errors5.otp4 && (
                          <p className="error mtn-2 ms-5">
                            Please Enter OTP for Varification
                          </p>
                        )}
                    </div>
                  </div>

                  <div className="single-input-item mx-2">
                    <button
                      className="sqr-btn w-100"
                      type="submit"
                    // data-bs-dismiss="modal"
                    >
                      Verify OTP
                    </button>
                  </div>
                  <div className="single-input-item mx-2">
                    <div className="login-reg-form-meta d-flex align-items-center justify-content-end">
                      <Link
                        to={`/resendOTP`}
                        className="forget-pwd"
                      // data-bs-dismiss="modal"
                      >
                        <b>Resend OTP</b>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
