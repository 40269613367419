// import { createStore } from "redux";
// import rootReducers from "../reducers/Reducer";

// const loadState = () => {
//     try {
//         const serializedState = localStorage.getItem('state')
//         if (serializedState === null) {
//             return undefined;
//         }
//         return JSON.parse(serializedState)
//     } catch (error) {
//         // console.log(error)
//         return undefined
//     }
// }

// const saveState = (state) => {
//     try {
//         const serializedState = JSON.stringify(state)
//         localStorage.setItem('state', serializedState)
//     } catch (error) {
//         // console.log(error)
//     }
// }

// const persistedState = loadState();

// const store = createStore(rootReducers, persistedState);

// store.subscribe(() => saveState(store.getState()));

// export default store;

// import { createStore } from "redux";
// import CryptoJS from 'crypto-js';
// import rootReducers from "../reducers/Reducer";

// // Secret key for encryption/decryption (securely manage this key)
// const SECRET_KEY = 'your-secret-key';

// // Encrypt function
// const encryptData = (data) => {
//     return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
// };

// // Decrypt function
// const decryptData = (cipherText) => {
//     const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY);
//     return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
// };

// // Load state from localStorage and decrypt it
// const loadState = () => {
//     try {
//         const serializedState = localStorage.getItem('state');
//         if (serializedState === null) {
//             return undefined;
//         }
//         return decryptData(serializedState);
//     } catch (error) {
//         console.log('Load state error:', error);
//         return undefined;
//     }
// };

// // Save state to localStorage and encrypt it
// const saveState = (state) => {
//     try {
//         const serializedState = encryptData(state);
//         localStorage.setItem('state', serializedState);
//     } catch (error) {
//         console.log('Save state error:', error);
//     }
// };

// const persistedState = loadState();

// const store = createStore(rootReducers, persistedState);

// store.subscribe(() => saveState(store.getState()));

// export default store;

import { createStore } from 'redux';
import CryptoJS from 'crypto-js';
import rootReducer from '../reducers/Reducer';

// Secret key for encryption/decryption
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || 'your-secret-key';

// Encrypt function
const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

// Decrypt function
const decryptData = (cipherText) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// Load state from localStorage and decrypt it
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return decryptData(serializedState);
  } catch (error) {
    console.log('Load state error:', error);
    return undefined;
  }
};

// Save state to localStorage and encrypt it
const saveState = (state) => {
  try {
    const serializedState = encryptData(state);
    localStorage.setItem('state', serializedState);
  } catch (error) {
    console.log('Save state error:', error);
  }
};

const persistedState = loadState();

const store = createStore(rootReducer, persistedState);

store.subscribe(() => saveState(store.getState()));

export default store;
