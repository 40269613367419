import { SHOW_POPUP, HIDE_POPUP, CHECK_FIRST_VISIT } from "../constants/Constants" ;

const initialState = {
  showPopup: false,
  visited: false,
};

const popupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_POPUP:
      return { ...state, showPopup: true };
    case HIDE_POPUP:
      return { ...state, showPopup: false, visited: true }; // Mark as visited when popup is closed
    case CHECK_FIRST_VISIT:
      if (!state.visited) {
        return { ...state, showPopup: true, visited: true };
      }
      return state;
    default:
      return state;
  }
};

export default popupReducer;


// import { SHOW_POPUP, HIDE_POPUP, CHECK_FIRST_VISIT, INIT_SESSION } from "../constants/Constants" ;

// const initialState = {
//   showPopup: false,
//   sessionInitialized: false,
// };

// const popupReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case SHOW_POPUP:
//       return { ...state, showPopup: true };
//     case HIDE_POPUP:
//       return { ...state, showPopup: false };
//     case INIT_SESSION:
//       // Ensure popup is shown only if session is not initialized
//       return {
//         ...state,
//         showPopup: !state.sessionInitialized,
//         sessionInitialized: true,
//       };
//     default:
//       return state;
//   }
// };

// export default popupReducer;
