import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  trackOrderDetails,
  getOrderDetails,
} from "../../services/Order.service";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function TrackOrderDetails() {
  const location = useLocation();
  const token = useSelector((state) => state.status.restaurant.Token);
  const currencySymbol = useSelector(
    (state) => state.status.restaurant.currency_symbol
  );

  const [orderDetailsData, setOrderDetailsData] = useState();
  const [waybill, setWaybill] = useState();
  const [trackOrderData, setTrackOrderData] = useState();
  const [orderDetails, setOrderDetails] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.orderDetails) {
      setOrderDetailsData(location.state.orderDetails);
      setWaybill(
        location.state.orderDetails && location.state.orderDetails.waybill
      );
      trackOrderDetails(token, location.state.orderDetails.waybill).then(
        (res) => {
          // console.log(res?.data);
          setTrackOrderData(res?.data.ShipmentData?.[0]);
        }
      );
    }
    fetchTrackOrder();

    getOrderDetails(token, location.state.orderDetails.order).then((res) => {
      // console.log(res?.data);
      setOrderDetails(res?.data.results);
    });

    // eslint-disable-next-line
  }, [location.state.orderDetails]);

  const fetchTrackOrder = () => {
    // setIsLoading(true);
    waybill &&
      trackOrderDetails(token, waybill).then((res) => {
        // console.log(res?.data);
        setTrackOrderData(res?.data.ShipmentData?.[0]);
        // setIsLoading(false);
      });
  };

  return (
    <div style={{ minHeight: "800px" }}>
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
      />

      <div className="page-content fade-in-up p-3">
        <div className="ibox">
          <div className="ibox-body my-4">
            <div className="d-flex justify-content-center">
              {orderDetailsData && (
                <div className="text-center">
                  <label className="orderID fw-bolder">
                    Order ID - #{orderDetailsData.order}
                  </label>
                  <br />
                  <label className="orderIDStatus">
                    Status - {trackOrderData?.Shipment?.Status?.Instructions}
                    {/* {waybill
                      ? orderDetailsData.status === "Canceled"
                        ? "Order Canceled"
                        : "Prepared for Dispatch"
                      : "Order Canceled before Confirmed"} */}
                  </label>
                </div>
              )}
            </div>

            {waybill && <div className="track-line my-4"></div>}

            {waybill && trackOrderData && (
              <div className="row">
                <div className="order-track col-lg-4 px-2">
                  {trackOrderData.Shipment &&
                    trackOrderData.Shipment.Scans.map((item, i) => (
                      <div className="order-track-step" key={i}>
                        <div className="order-track-text">
                          <span className="order-track-text-sub me-4 float-right">
                            {item.ScanDetail.ScanDateTime.split("T")[0]}
                          </span>
                          <br />
                          <span className="order-track-text-sub me-4 float-right">
                            {new Date(
                              item.ScanDetail.ScanDateTime
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: true,
                            })}

                            {/* {item.ScanDetail.ScanDateTime.split("T")[1]} */}
                          </span>
                        </div>
                        <div className="order-track-status">
                          <span className="order-track-status-dot"></span>
                          <span className="order-track-status-line"></span>
                        </div>
                        <div className="order-track-text">
                          <span className="order-track-text-sub">
                            {item.ScanDetail.Instructions}
                          </span>
                          <p className="order-track-text-stat">
                            {item.ScanDetail.ScannedLocation.replaceAll(
                              "_",
                              " "
                            )}
                          </p>
                        </div>
                      </div>
                    ))}

                  {/* <div className="order-track-step">
                    <div className="order-track-text">
                      <span className="order-track-text-sub me-4"></span>
                      <br />
                      <span className="order-track-text-sub me-4 float-right"></span>
                    </div>
                    <div className="order-track-status">
                      <span className="order-track-status-dot"></span>
                      <span className="order-track-status-line"></span>
                    </div>
                    <div className="order-track-text">
                      <span className="order-track-text-sub"></span>
                      <p className="order-track-text-stat"></p>
                    </div>
                  </div> */}
                </div>
                <div className="col-lg-8 mt-4">
                  <div className="row">
                    <div className="col-lg-12">
                      {/* <!-- Cart Table Area --> */}
                      <div
                        className="cart-table table-responsive"
                        style={{ padding: "0px" }}
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="pro-thumbnail">Thumbnail</th>
                              <th className="pro-title">Product</th>
                              <th className="pro-id">Product ID</th>
                              <th className="pro-color">Color</th>
                              <th className="pro-size">Size</th>
                              <th className="pro-price">Price</th>
                              <th className="pro-quantity">Quantity</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderDetails?.length > 0 &&
                              orderDetails?.map((item, i) =>
                                item.order_item_status === "Available" ? (
                                  <tr key={i}>
                                    <td className="pro-thumbnail">
                                      <img
                                        className="img-fluid w-50"
                                        src={item.color.image_url.split("@")[0]}
                                        alt="Product"
                                      />
                                    </td>
                                    <td className="pro-title">
                                      {item.product.product_name}
                                    </td>
                                    <td className="pro-id">
                                      {item.product.product_id}
                                    </td>
                                    <td className="pro-color">
                                      {item.color.color.split(",")[0]}
                                    </td>
                                    <td className="pro-size">
                                      {item.color.size.name}
                                    </td>
                                    <td className="pro-price">
                                      {currencySymbol === "₹" ? "₹" : "$"}
                                      {(item.color.price * 1).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </td>
                                    <td className="pro-quantity">
                                      {item.quantity}
                                    </td>
                                    <td>
                                      {currencySymbol === "₹" ? "₹" : "$"}
                                      {(
                                        item.color.price * item.quantity
                                      ).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </td>
                                  </tr>
                                ) : null
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
