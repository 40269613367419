import React, { useState, useEffect, useRef } from "react";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  userRegistration,
  createCustomer,
} from "../../services/UserLogin.service";
import { PATH, RESTAURANT_ID } from "../../redux/constants/Constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCartItems } from "../../services/Cart.service";
import { userCartItems } from "../../redux/actions/Actions";

export default function Register() {
  const [countryCode, setCountryCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState(null);
  const [password, setPassword] = useState("");
  const [valid, setValid] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const cartId = useSelector(
    (state) => state?.cart?.cart?.results && state?.cart?.cart?.results[0]?.id
  );
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.status?.restaurant?.Token);
  const currencySymbol = useSelector(
    (state) => state?.status?.restaurant?.currency_symbol
  );
  const navigate = useNavigate();
  // console.log(token);

  let intlTelInputRef = useRef(null);

  const {
    register,
    handleSubmit: handleSubmitSignUp,
    setError,
    setValue,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const inputMobileNumber = () => {
    const mobileNumberPattern = /^[0-9]{10}$/;
    const mobileNumber = intlTelInputRef.current?.state.value;
    const code = intlTelInputRef.current?.state.title.split(" ")[2];
    if (mobileNumberPattern.test(mobileNumber)) {
      clearErrors("mobileNumber");
      setValue("mobileNumber", code + mobileNumber);
      setMobileNumber(code + mobileNumber);
    } else {
      setError("mobileNumber", {
        type: "manual",
        message: "Enter Valid Mobile Number",
      });
    }

    if (!mobileNumber) {
      setError("mobileNumber", {
        type: "manual",
        message: "Enter Mobile Number",
      });
    }
    // console.log("Mobile Number", code + mobileNumber);
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);

    // Validate the password
    const errors = [];

    // At least 8 characters
    if (value) {
      if (value.length < 8) {
        errors.push("Password must contain at least 8 characters");
      }

      // At least one lowercase letter
      if (!/[a-z]/.test(value)) {
        errors.push("one lowercase letter");
      }

      // At least one uppercase letter
      if (!/[A-Z]/.test(value)) {
        errors.push("one uppercase letter");
      }

      // At least one number
      if (!/\d/.test(value)) {
        errors.push("one number");
      }

      // At least one special character
      if (!/[!@#$%^&*()_+\[\]{};:'"\|,.<>\/?]/.test(value)) {
        errors.push("one special character");
      }
    }
    // else {
    //   errors.push("Enter Password");
    // }

    setValid(errors.length === 0);
    setPasswordErrors(errors);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    cartId &&
      getCartItems(token, cartId).then((res) => {
        res && dispatch(userCartItems(res?.data));

        // console.log(res?.data);
      });
    fetchAPIs();
  }, []);

  const fetchAPIs = () => {
    cartId &&
      getCartItems(token, cartId).then((res) => {
        dispatch(userCartItems(res?.data));

        // console.log(res?.data);
      });
    // Get user's location using Geolocation API
    // navigator.geolocation.getCurrentPosition(
    //   (position) => {
    //     const { latitude, longitude } = position.coords;
    //     // Make a request to a reverse geocoding service to get the country code
    //     axios
    //       .get(
    //         `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
    //       )
    //       .then((response) => {
    //         // Extract the country code from the response
    //         const countryCode = response.data.countryCode.toUpperCase(); // Ensure uppercase
    //         setCountryCode(countryCode);
    //       })
    //       .catch((error) => {
    //         console.error("Error fetching country code:", error);
    //       });
    //   },
    //   (error) => {
    //     console.error("Error getting user location:", error);
    //   }
    // );
  };

  const signUp = (item) => {
    const data = {
      salutation: item.salutation,
      first_name: item.firstName,
      password: item.password,
      last_name: item.lastName,
      restaurant_id: RESTAURANT_ID,
      verified: "N",
      username: item.email,
      email: item.email,
      phone_number: mobileNumber,
    };
    if (valid) {
      userRegistration(token, data).then((res) => {
        // console.log(res);

        if (res.status === 200) {
          // console.log(res?.data);

          const data = {
            salutation: item.salutation,
            last_access: new Date(),
            first_name: item.firstName,
            email: item.email,
            last_name: item.lastName,
            phone_number: mobileNumber,
            customer_id: res?.data.id,
            extra: "extra",
          };

          createCustomer(token, data, res?.data.id).then((res) => {
            // console.log(res);

            toast.success(
              <React.Fragment>
                Registration is successful! <br />
                Please verify your email to access your account.</React.Fragment>,
              {
                autoClose: 2500,
                style: {
                  backgroundColor: "black",
                  color: "white",
                },
                containerId: "register",
              }
            );

            reset();

            setTimeout(() => {
              navigate(`/login`);
            }, 3000);
          });
        } else if (res.status === 400) {
          toast.error(
            <React.Fragment>
              This email address is already registered!<br />
              Please use a different one.
            </React.Fragment>,
            {
              autoClose: 5000,
              style: {
                backgroundColor: "black",
                color: "white",
                // width: "400px",
                // marginLeft: "-40px",
              },
              containerId: "register",
              toastId: "signUp",
            }
          );
        }
      });
    }
  };

  return (
    <div
      className="login-register-wrapper section-padding"
      // style={{ minHeight: "610px" }}
      style={{ minHeight: "800px" }}
    >
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
        toastId="signUp"
        containerId="register"
      />

      <div className="container py-2">
        <div className="member-area-from-wrap">
          <div className="row d-flex justify-content-center mbn-20">
            <div className="col-lg-7">
              {/* <!-- Register Content Start --> */}
              <div>
                <div className="login-reg-form-wrap signup-form">
                  <h2>Sign Up</h2>
                  <form
                    action="#"
                    method="post"
                    onSubmit={handleSubmitSignUp(signUp)}
                  >
                    <div className="row mtn-15">
                      <div className="col-lg-2">
                        <div className="single-input-item mb-3 mx-2">
                          {/* <input
                            type="text"
                            placeholder="Salutation"
                            {...register("salutation", {
                              required: "Enter Salutation",
                            })}
                          /> */}

                          <select
                            className="form-select"
                            defaultValue=""
                            {...register("salutation", {
                              required: "Enter Salutation",
                            })}
                          >
                            <option value="" disabled>
                              Salutation
                            </option>
                            <option value="Mr.">Mr.</option>
                            <option value="Mrs.">Mrs.</option>
                            <option value="Miss.">Miss.</option>
                            <option value="Ms.">Ms.</option>
                            <option value="Other">Other</option>
                          </select>
                          {errors.salutation && (
                            <p className="error">{errors.salutation.message}</p>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-lg-6">
                        <div className="single-input-item mb-3">
                          <input
                            type="text"
                            placeholder="Username"
                            {...register("username", {
                              required: "Enter Username",
                            })}
                          />
                          {errors.username && (
                            <p className="error">{errors.username.message}</p>
                          )}
                        </div>
                      </div> */}
                      <div className="col-lg-5">
                        <div className="single-input-item mb-3 mx-2">
                          <input
                            type="text"
                            placeholder="First Name"
                            {...register("firstName", {
                              required: "Enter First Name",
                            })}
                            maxLength={20}
                          />
                          {errors.firstName && (
                            <p className="error">{errors.firstName.message}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="single-input-item mb-3 mx-2">
                          <input
                            type="text"
                            placeholder="Last Name"
                            {...register("lastName", {
                              required: "Enter Last Name",
                            })}
                            maxLength={20}
                          />
                          {errors.lastName && (
                            <p className="error">{errors.lastName.message}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="single-input-item mb-3 mx-2">
                          <input
                            type="email"
                            placeholder="Email"
                            {...register("email", {
                              required: "Enter Email",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Invalid Email Address",
                              },
                            })}
                            maxLength={50}
                          />
                          {errors.email && (
                            <p className="error">{errors.email.message}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-item mb-3 mx-2">
                          <IntlTelInput
                            {...register("mobileNumber", {
                              required: "Enter Mobile Number",
                            })}
                            ref={intlTelInputRef}
                            containerClassName="intl-tel-input"
                            inputClassName="form-control"
                            defaultCountry={
                              currencySymbol === "₹" ? "in" : "us"
                            }
                            autoPlaceholder=""
                            placeholder="Mobile Number"
                            onlyCountries={["in", "us"]}
                            onPhoneNumberChange={() => inputMobileNumber()}
                            separateDialCode={true}
                          />
                          {errors.mobileNumber && (
                            <p className="error">
                              {errors.mobileNumber.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="single-input-item mb-3 mx-2">
                          <input
                            type="password"
                            placeholder="Enter Your Password"
                            {...register("password", {
                              required: "Enter Password",
                            })}
                            onChange={handlePasswordChange}
                            maxLength={20}
                          />
                          {/* {errors.password && (
                            <p className="error">{errors.password.message}</p>
                          )} */}

                          {!valid && (
                            <ul className="">
                              {passwordErrors.map((error, index) => (
                                <li className="p-error" key={index}>
                                  {error}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-item mb-3 mx-2">
                          <input
                            type="password"
                            placeholder="Confirm Your Password"
                            {...register("repeatPassword", {
                              required: "Confirm Your Password",
                              validate: (value) => {
                                if (watch("password") !== value) {
                                  return "Sorry! Your Password do not Match";
                                }
                              },
                            })}
                            maxLength={20}
                          />
                          {errors.repeatPassword && (
                            <p className="error">
                              {errors.repeatPassword.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="single-input-item">
                      <div className="login-reg-form-meta">
                        <div className="remember-meta">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="subnewsletter"
                            />
                            <label
                              className="form-check-label"
                              for="subnewsletter"
                            >
                              Subscribe to Our Newsletter
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="form-check ms-2 mt-2">
                      <input
                        className="form-check-input subscription-check-box"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        {...register("isAgreed", {
                          required:
                            "You must agree to our terms and conditions.",
                        })}
                      // checked
                      />

                      <label
                        className="form-check-label pointer"
                        for="flexCheckDefault"
                      >
                        By continuing, I agree to the
                        <Link
                          to={`/terms-and-conditions`}
                          target="_blank"
                          className="policy mx-1"
                          style={{ color: "#008000" }}
                        >
                          Terms & Conditions
                        </Link>
                        and
                        <Link
                          to={`/privacy-policy`}
                          target="_blank"
                          className="policy mx-1"
                          style={{ color: "#008000" }}
                        >
                          Privacy Policy
                        </Link>
                        of BambooSA Agro Pvt Ltd.
                      </label>
                      {errors.isAgreed && (
                        <p className="error ms-1" style={{ marginTop: "35px" }}>
                          {errors.isAgreed.message}
                        </p>
                      )}
                    </div>
                    <div className="single-input-item mx-2">
                      <button className="sqr-btn w-100" type="submit">
                        Register
                      </button>
                    </div>
                    <div className="single-input-item ">
                      <div className="login-reg-form-meta d-flex align-items-center justify-content-end">
                        <Link
                          to={`/login`}
                          className="forget-pwd"
                          data-bs-dismiss="modal"
                        >
                          <b>Already have an Account? Sign In</b>
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* <!-- Register Content End --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
